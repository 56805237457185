<template>
	<v-navigation-drawer v-model="drawer" fixed app dark class="nav"
		width="250">
		<v-toolbar height="70px" flat style="background: transparent">
			<img :src="Logo" width="120px" alt="Farme Assinatura" class="mr-3" />
			<span style="color: #312b1d !important" class="versao pointer" @click="dialog_atualizacoes = true">Versão: {{ version }}</span>
		</v-toolbar>

		<v-list dense style="background: transparent; color: #312b1d; ">
			<v-list-tile v-if="eh_colaborador" to="/colaborador/dashboard">
				<v-list-tile-action>
					<v-icon>apps</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>Dashboard</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>

			<v-list-tile v-if="eh_colaborador" to="/colaborador/pedidos">
				<v-list-tile-action>
					<v-icon>shopping_cart</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>Far.me Pontual</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>

			<v-list-tile v-if="eh_colaborador" to="/colaborador/assinaturas">
				<v-list-tile-action>
					<v-icon small>fa-sharp fa-regular fa-file-signature</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>Assinaturas</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>

			<v-list-group>
				<v-icon slot="prependIcon">fa-regular fa-building</v-icon>
				<template v-slot:activator>
					<v-list-tile>
						<v-list-tile-title>Orçamentos</v-list-tile-title>
					</v-list-tile>
				</template>

				<v-list-tile to="/colaborador/orcamentos">
					<v-list-tile-action>
						<v-icon>payments</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Orçamento B2B</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/orcamentosB2C">
					<v-list-tile-action>
						<v-icon>home</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Orçamento B2C</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>

			<v-list-tile to="/colaborador/orcamentos-externos" v-if="false">
				<v-list-tile-action>
					<v-icon>fas fa-file-import</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>Orçamentos externos</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>

			<div class="my-4" v-if="eh_colaborador" />

			<v-list-group v-if="eh_colaborador">
				<v-icon slot="prependIcon">fa-regular fa-building</v-icon>
				<template v-slot:activator>
					<v-list-tile>
						<v-list-tile-title>Back Office</v-list-tile-title>
					</v-list-tile>
				</template>

				<v-list-tile to="/colaborador/compra">
					<v-list-tile-action>
						<v-icon>add_shopping_cart</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Compra</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/calendario" v-if="permite_planejamento">
					<v-list-tile-action>
						<v-icon>calendar_month</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Planejamento</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/farmaceutico">
					<v-list-tile-action>
						<v-icon>receipt_long</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Farmacêutico</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/atendimento">
					<v-list-tile-action>
						<v-icon>done_all</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Atendimento</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/cobranca">
					<v-list-tile-action>
						<v-icon>account_balance</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Cobrança</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile v-if="eh_colaborador" to="/colaborador/faturas">
					<v-list-tile-action>
						<v-icon>attach_money</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Faturas</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>

			<div class="my-4" v-if="eh_colaborador" />

			<v-list-group
				v-if="eh_colaborador"
				value="true">
				<v-icon class="prepend-icon" slot="prependIcon">task</v-icon>
				<template v-slot:activator>
					<v-list-tile>
						<v-list-tile-title>Processo</v-list-tile-title>
					</v-list-tile>
				</template>

				<v-list-tile v-if="!hideInvoicingAndPickingProcess" to="/colaborador/picking">
					<v-list-tile-action>
						<v-icon small>fa-light fa-inbox</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Picking</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile v-if="!hideInvoicingAndPickingProcess" to="/colaborador/faturamento">
					<v-list-tile-action>
						<v-icon>attach_money</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Faturamento</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile v-if="listOrdersFeatureIsEnabled" to="/colaborador/acompanhamento-pedidos">
					<v-list-tile-action>
						<v-icon>widgets</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Pedidos</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile v-if="preProductionFeatureIsEnabled" to="/colaborador/pre-producao">
					<v-list-tile-action>
						<v-icon>inventory_2</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Pré-Produção</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/producao">
					<v-list-tile-action>
						<v-icon>inventory_2</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Produção</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/produzir">
					<v-list-tile-action>
						<v-icon>inventory_2</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Produção - operador</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/conferencia">
					<v-list-tile-action>
						<v-icon small>fa-thin fa-check</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Conferência</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/expedicao">
					<v-list-tile-action>
						<v-icon>fas fa-boxes</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Expedição</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>

			<div class="my-4" v-if="eh_colaborador" />

			<v-list-group v-if="eh_colaborador">
				<v-icon slot="prependIcon">local_shipping</v-icon>
				<template v-slot:activator>
					<v-list-tile>
						<v-list-tile-title>Logística</v-list-tile-title>
					</v-list-tile>
				</template>

				<v-list-tile to="/colaborador/prontas-para-envio">
					<v-list-tile-action>
						<v-icon>rv_hookup</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Prontas para envio</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/expedicao-base-entregas">
					<v-list-tile-action>
						<v-icon>moped</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Base de entregas</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<!-- <v-list-tile to="/colaborador/routes">
					<v-list-tile-action>
						<v-icon>fmd_good</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Rotas</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile> -->

				<v-list-tile to="/colaborador/expedicao-acompanhamento">
					<v-list-tile-action>
						<v-icon>poll</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Acompanhamento</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/impressao">
					<v-list-tile-action>
						<v-icon>fas fa-print</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Impressão</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>

			<div class="my-4" v-if="eh_colaborador" />

			<v-list-group v-if="eh_colaborador">
				<v-icon slot="prependIcon">app_registration</v-icon>
				<template v-slot:activator>
					<v-list-tile>
						<v-list-tile-title>Cadastro</v-list-tile-title>
					</v-list-tile>
				</template>

				<v-list-tile to="/colaborador/pessoas">
					<v-list-tile-action>
						<v-icon>people</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Pessoas</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/responsaveis">
					<v-list-tile-action>
						<v-icon>
							person
						</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Responsáveis</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<!-- <v-list-tile to="/colaborador/medicamentos">
					<v-list-tile-action>
						<v-icon>medication</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Medicamentos</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile> -->

				<v-list-tile to="/colaborador/produtos">
					<v-list-tile-action>
						<v-icon>inventory</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Produtos</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/casarepouso">
					<v-list-tile-action>
						<v-icon>real_estate_agent</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Casas de repouso</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile v-if="rastreabilidadePorFotoFeatureIsEnabled" to="/colaborador/rastreabilidade" >
					<v-list-tile-action>
						<v-icon>inventory</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Rastreabilidade</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>

			<div class="my-4" />

			<v-list-group
				v-if="eh_clinica || eh_admin">
				<v-icon slot="prependIcon">fas fa-clinic-medical</v-icon>
				<template v-slot:activator>
					<v-list-tile>
						<v-list-tile-title>Clínica</v-list-tile-title>
					</v-list-tile>
				</template>

				<v-list-tile to="/clinica/gtm">
					<v-list-tile-action>
						<v-icon>local_pharmacy</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>GTM</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/clinica/pacientes">
					<v-list-tile-action>
						<v-icon>fas fa-users</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Pacientes</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/clinica/molecula">
					<v-list-tile-action>
						<v-icon>fas fa-dna</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Molecula</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/clinica/principioativo">
					<v-list-tile-action>
						<v-icon>fas fa-tablets</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Princípio Ativo</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/clinica/dosemaxima">
					<v-list-tile-action>
						<v-icon>fas fa-exclamation-circle</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Dose Máxima</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>

			<div class="my-4" v-if="eh_colaborador" />

			<v-list-group v-if="eh_colaborador">
				<v-icon slot="prependIcon">trending_up</v-icon>
				<template v-slot:activator>
					<v-list-tile>
						<v-list-tile-title>Relatórios</v-list-tile-title>
					</v-list-tile>
				</template>

				<v-list-tile v-if="eh_rt || eh_admin" to="/relatorios/estoque">
					<v-list-tile-action>
						<v-icon>fas fa-cubes</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Estoque Paciente</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>

			<div class="my-4" />

			<v-list-group
				v-if="$store.getters.eh_administrador || $store.getters.eh_rt">
				<v-icon slot="prependIcon">settings</v-icon>
				<template v-slot:activator>
					<v-list-tile>
						<v-list-tile-title>Configurações</v-list-tile-title>
					</v-list-tile>
				</template>

				<v-list-tile to="/colaborador/configuracoes/usuarios">
					<v-list-tile-action>
						<v-icon>fas fa-user-friends</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Usuários</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/configuracoes/calendario">
					<v-list-tile-action>
						<v-icon>fas fa-calendar-alt</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Calendário</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile to="/colaborador/configuracoes/tag">
					<v-list-tile-action>
						<v-icon>fas fa-user-tag</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Tags</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile @click="dialog_configuracao = true">
					<v-list-tile-action>
						<v-icon>fas fa-dollar-sign</v-icon>
					</v-list-tile-action>
					<v-list-tile-content>
						<v-list-tile-title>Valor do serviço</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>

			<div class="my-4" />

			<v-list-tile @click="sairDoColaborador()">
				<v-list-tile-action>
					<v-icon>exit_to_app</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title> {{ mensagem_sair }} </v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>

			<v-list-tile @click="sair">
				<v-list-tile-action>
					<v-icon>exit_to_app</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>Sair do sistema</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>

			<div class="my-4" />

			<v-list-tile :href="link_outra_cidade" @click="mudarDeCidade">
				<v-list-tile-action>
					<img  width="30px" v-if="city !== 'BH'" :src="IconeMg" alt="Ícone estado BH">
					<img  width="30px" v-else :src="IconeSp" alt="Ícone estado SP">
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>{{ change_city_text }}</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>
		</v-list>

		<div
			class="usuario white--text mx-4 mb-4"
			style="font-weight: 500; font-size: 12px">
			<p>
				{{ $store.getters.user.nome }}
			</p>
			<p>
				{{ $functions.getPermissaoLabel($store.getters.user.permissao) }}
			</p>
		</div>

		<!-- Modal Configuração -->
		<v-dialog
			persistent
			v-model="dialog_configuracao"
			max-width="600px"
			transition="fade">
			<DialogConfiguracoes
				:fecha.sync="dialog_configuracao"
				v-if="dialog_configuracao"/>
		</v-dialog>

		<!-- Modal Versões -->
		<v-dialog
			persistent
			v-model="dialog_atualizacoes"
			max-width="600px"
			transition="fade">
			<DialogAtualizacoes
				:fecha.sync="dialog_atualizacoes"
				v-if="dialog_atualizacoes"/>
		</v-dialog>
	</v-navigation-drawer>
</template>

<script>
import Logo from '@Assets/images/FarmeOS.svg';
import unleash from '@Util/unleash';
import { isProd } from '@Config/environments';
import { getCurrentCompanyUnit, setCurrentCompanyUnit } from '@Config/unidades';
import { featureFlagsMixin } from '@Mixins/feature-flag';
import { featureFlags } from '@Consts/feature-flags';
import IconeMg from '@Assets/images/mg.png';
import IconeSp from '@Assets/images/sp.png';
import DialogAtualizacoes from './dialog-atualizacoes';
import DialogConfiguracoes from './dialog-configuracoes';
import Mixin from '../../usuario/esqueleto/mixin';

export default {
	name: 'Esqueleto',
	mixins: [Mixin, featureFlagsMixin],
	components: {
		DialogAtualizacoes,
		DialogConfiguracoes
	},
	data: () => ({
		dialog_atualizacoes: false,
		dialog_configuracao: false,
		drawer: true,
		Logo,
		IconeMg,
		IconeSp,
		isProd,
		rastreabilidadePorFotoFeatureIsEnabled: unleash.isEnabled('FARMEBOX_RASTREABILIDADE_POR_FOTO'),
		listOrdersFeatureIsEnabled: unleash.isEnabled('FARMEBOX_LIST_ORDERS_IN_PROCESS'),
		hideInvoicingAndPickingProcess: unleash.isEnabled('FARMEBOX_HIDE_PICKING_AND_INVOICING')
	}),
	created() {
		if (this.$vuetify.breakpoint.mdAndDown)
			this.drawer = false;

		this.startFeatureFlags();
	},
	mounted() {
		this.$root.$on('handle_menu', () => {
			this.drawer = !this.drawer;
		});
	},
	beforeDestroy() {
		this.$root.$off('handle_menu');
	},
	methods: {
		startFeatureFlags() {
			unleash.on(
				'update',
				() => {
					this.rastreabilidadePorFotoFeatureIsEnabled = unleash.isEnabled(featureFlags.rastreabilidade_por_foto);
					this.listOrdersFeatureIsEnabled = unleash.isEnabled(featureFlags.list_orders_in_process);
					this.hideInvoicingAndPickingProcess = unleash.isEnabled(featureFlags.hide_invoicing_and_picking_process);
				}
			);
		},
		sairDoColaborador() {
			this.$store.dispatch('SET_VISAO_COLABORADOR', false);
			this.$router.push({ name: 'Perfil' });
		},
		mudarDeCidade() {
			const anotheCityId = getCurrentCompanyUnit().id === 1 ? 2 : 1;
			setCurrentCompanyUnit(anotheCityId);
		}
	},
	computed: {
		version() {
			return this.$store.getters.version;
		},
		eh_colaborador() {
			return this.$store.getters.eh_colaborador;
		},
		eh_clinica() {
			return this.$store.getters.eh_clinica;
		},
		eh_admin() {
			return this.$store.getters.eh_administrador;
		},
		eh_rt() {
			return this.$store.getters.eh_rt;
		},
		permite_planejamento() {
			return this.$store.getters.allow_update_calendario;
		},
		mensagem_sair() {
			return this.eh_colaborador ? 'Sair do colaborador' : 'Sair do vendedor';
		},
		city() {
			return getCurrentCompanyUnit().key;
		},
		change_city_text() {
			return this.city === 'BH' ? 'Mudar para SP' : 'Mudar para BH';
		},
		link_outra_cidade() {
			return window.location.href;
		},
		preProductionFeatureIsEnabled() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.pre_production_process);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.usuario {
	background: rgba($primary, 0.5);
	color: $green !important;
	border-radius: 10px;
	padding: 10px;

	p {
		margin-bottom: 0px;
	}

	.versao {
		font-size: 12px;
		opacity: 0.4;
		position: relative;
		top: 7px;
	}
}

::v-deep .v-list {
	i {
		&.fas {
			font-size: 14px;
		}
		&.material-icons {
			font-size: 20px;
		}
	}

}

.nav {
	background: $primary_light !important;

	::v-deep .v-list__group,
	.v-list__group--active {
		&::before,
		&::after {
			display: none;
		}

		.v-list__group__header {
			background: rgba($primary, 0.5);
			margin: 0 15px !important;
			border-radius: 5px !important;

			&.v-list__group__header--active {
				.primary--text {
					color: $green !important;
				}
			}

			.v-list__group__header__prepend-icon {
				min-width: 34px !important;
				padding-left: 11px !important;

				.v-icon {
					transform: scale3d(0.75, 0.75, 1);
				}
			}

			.v-list__tile {
				margin: 0 !important;
				padding: 0;
			}

			.v-list__group__header__append-icon {
				transform: scale3d(0.75, 0.75, 1);
			}
		}

	}

	::v-deep .v-list__tile {
		margin: 3px 15px !important;
		border-radius: 5px !important;

		.v-list__tile__action {
			min-width: 40px !important;
		}
	}

	::v-deep .v-list__tile--active {
		background: $primary;
		color: $green !important;
		caret-color: $green !important;

		&:hover {
			background: transparent !important;
		}

		.v-icon {
			color: $green !important;
		}
	}

	::v-deep .v-icon{
		color: $green !important;
	}

	::v-deep .v-list__tile--link:hover{
		background-color: rgba($primary, 0.4)  !important;
		transition: all 0.5s;
	}

	::v-deep .v-list__group__header:hover{
		background: rgba($primary, 0.5) !important;
	}
}

	.v-list__group .v-list__group__header .v-list__group__header__prepend-icon .v-icon{
		font-size: 25px;
	}

</style>
