<template>
	<v-container fluid grid-list-md class="pa-0">
		<Loading v-if="loading" />
		<form v-else>
			<h3 class="my-3">Composição</h3>
			<v-layout wrap>
				<v-flex xs12 v-for="(item, index) of form.compositions" :key="index">
					<section
						style="
							background: rgba(255, 200, 117, 0.5) !important;
							border-radius: 5px;
						">
						<v-flex v-if="index > 0" class="display-flex justify-end">
							<v-btn
								flat
								small
								fab
								@click="removeIngredient(index)"
								class="ma-0">
								<v-icon>close</v-icon>
							</v-btn>
						</v-flex>
						<ActiveIngredientInput
							:model="form.compositions[index]"
							:activeIngredients="activeIngredients"
							:index="index"
							:unitsOfMeasurements="unitsOfMeasure"
							:atcsClassification="atcsClassification"
							@updateActiveIngredients="getActiveIngredients"
							@updateUnitsOfMeasurements="getUnitsOfMeasure"></ActiveIngredientInput>
					</section>
				</v-flex>

				<v-flex xs12 class="my-3">
					<section
						style="
								border: 2px dashed #342b1d;
								cursor: pointer;
								display: flex;
								justify-content: center;
								border-radius: 5px;
								filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
							"
						class="text-center py-3"
						@click="addIngredient()">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="19"
							height="19"
							viewBox="0 0 19 19"
							fill="none">
							<path
								d="M9.50605 1.00001C14.2038 1.00001 18.0121 4.8083 18.0121 9.50606C18.0121 14.2038 14.2038 18.0121 9.50605 18.0121C4.80829 18.0121 1 14.2038 1 9.50606C1 4.8083 4.80829 1.00001 9.50605 1.00001Z"
								stroke="#342B1D"
								stroke-width="1.65603"
								stroke-miterlimit="10"/>
							<path
								d="M9.50562 7.00939V12.0033"
								stroke="#342B1D"
								stroke-width="1.65603"
								stroke-miterlimit="10"
								stroke-linecap="round"/>
							<path
								d="M12.0038 9.50607H7.00989"
								stroke="#342B1D"
								stroke-width="1.65603"
								stroke-miterlimit="10"
								stroke-linecap="round"/>
						</svg>
						<strong class="ml-2"> Adicionar principio ativo </strong>
					</section>
				</v-flex>
			</v-layout>

			<FarmeDivider />

			<NewProductStepMedicineTechnicalData
				:form="form"
				:routesAdministration="routesAdministration"
				:prescriptionRules="prescriptionRules"
				:dosageForms="dosageForms"
				:atcsClassification="atcsClassification"
				:referenceDrug="referenceDrug.items"/>

			<h3 class="my-3">PBM</h3>
			<v-layout wrap fill-height>
				<v-flex xs12>
					<v-autocomplete
						v-model="form.pbmId"
						background-color="white"
						prepend-inner-icon="search"
						:items="pbms"
						box
						label="Programa PBM"
						clearable
						item-value="id"
						:item-text="(item) => `${item.program} ${item.platform ? `(${item.platform})` : '' }`"
						data-vv-name="PBM"
						v-validate="{ required: false }"
						append-outer-icon="add"
						@click:append-outer="openCreatePbmDialog = true"
						:error-messages="errors.collect('PBM')">
					</v-autocomplete>
				</v-flex>
			</v-layout>

			<FarmeDivider />

			<v-layout wrap>
				<v-flex xs12 md4>
					<FarmeCustomInputCheckbox
						id="option-out-of-box"
						v-model="form.properties.isOutOfBox"
						:disabled="form.properties.isLiquid"
						:checked="form.properties.isOutOfBox">
						<template v-slot:icon>
							<img
								src="@Assets/icons/fora_box_dark.svg"
								alt="Ícone de Fora box"
								width="35"/>
						</template>

						Fora Box
					</FarmeCustomInputCheckbox>
				</v-flex>

				<v-flex xs12 md4>
					<FarmeCustomInputCheckbox
						id="option-divisible"
						v-model="form.properties.isDivisible"
						:disabled="form.properties.isLiquid || !form.properties.isOutOfBox"
						:checked="form.properties.isDivisible">
						<template v-slot:icon>
							<img
								src="@Assets/icons/icone_pilulas_e_comprimidos.svg"
								alt="Ícone de divisível"
								width="27"/>
						</template>

						Unitarizado
					</FarmeCustomInputCheckbox>
				</v-flex>

				<v-flex xs12 md4>
					<FarmeCustomInputCheckbox
						id="option-liquid"
						v-model="form.properties.isLiquid"
						:disabled="!form.properties.isOutOfBox || form.properties.isDivisible"
						:checked="form.properties.isLiquid">
						<template v-slot:icon>
							<img
								src="@Assets/icons/liquido_dark.svg"
								alt="Ícone de líquido"
								width="48"/>
						</template>

						Líquido
					</FarmeCustomInputCheckbox>
				</v-flex>
			</v-layout>
		</form>

		<v-dialog
			v-model="openCreatePbmDialog"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogCreatePbm
					v-if="openCreatePbmDialog"
					@close="closeCreatePbmDialog"/>
			</transition>
		</v-dialog>

	</v-container>
</template>

<script>
import { UnitOfMeasurePresenter } from '@Presenter/unit-of-measure-presenter';
import { ActiveIngredientPresenter } from '@Presenter/active-ingredient-presenter';
import { PbmPresenter } from '@Presenter/pbm-presenter';
import { DrugPresenter } from '@Presenter/drug-presenter';
import { RouteAdministrationPresenter } from '@Presenter/route-administration-presenter';
import { AtcClassificationPresenter } from '@Presenter/atc-classification-presenter';
import { PrescriptionRulesPresenter } from '@Presenter/prescription-rules-presenter';
import FarmeCustomInputCheckbox from '@Componentes/farme-custom-input-checkbox.vue';
import FarmeDivider from '@Componentes/farme-divider.vue';
import { pbmTypeLabel } from '@Consts/product/pbm';
import { debounce } from 'lodash';
import DialogCreatePbm from '../../../dialog-new-pbm.vue';
import NewProductStepMedicineTechnicalData from './technical-data.vue';
import Loading from '../../loading.vue';
import ActiveIngredientInput from './active-ingredient-input.vue';

export default {
	name: 'NewProductStepMedicine',
	components: {
		DialogCreatePbm,
		FarmeCustomInputCheckbox,
		FarmeDivider,
		NewProductStepMedicineTechnicalData,
		Loading,
		ActiveIngredientInput
	},
	inject: ['$validator'],
	props: {
		form: {
			required: true,
			type: Object
		},
		productForm: {
			required: true,
			type: Object
		},
		updateLoading: {
			required: true,
			type: Function
		},
		dosageForms: {
			required: true,
			type: Array
		}
	},
	async created() {
		try {
			this.updateLoading(true);

			const activeIngredientsIds = this.form?.compositions?.map(c => c.activeIngredient?.id);

			await Promise.all([
				this.getPbms(),
				this.getUnitsOfMeasure(),
				this.getActiveIngredients({ ids: activeIngredientsIds }),
				this.getRoutesAdministration(),
				this.getAtcsClassification(),
				this.getPrescriptionRules()
			]);

			if (this.form.referenceDrugId)
				await this.getReferenceDrugs({ paginate: false });
			this.loading = false;
			this.updateLoading(false);
		} catch (error) {
			this.$store.dispatch('SHOW_SNACKBAR', {
				message: 'Erro ao carregar dados do formulário',
				color: 'error'
			});
		}
	},
	data() {
		return {
			loading: true,
			pbms: [],
			unitsOfMeasure: [],
			routesAdministration: [],
			activeIngredients: [],
			atcsClassification: [],
			openCreatePbmDialog: false,
			referenceDrug: {
				loading: false,
				term: null,
				items: []
			},
			prescriptionRules: {
				loading: false,
				items: []
			}
		};
	},
	computed: {
		referenceDrugIsRequired() {
			return ['GENERICO', 'SIMILAR'].includes(this.form.type);
		}
	},
	methods: {
		async getUnitsOfMeasure() {
			const unitsOfMeasureResponse = await UnitOfMeasurePresenter.findAll();
			this.unitsOfMeasure = unitsOfMeasureResponse.items.map(item => ({
				symbol: item.symbol,
				name: `[${item.symbol}] - ${item.name}`
			}));
		},
		async getActiveIngredients({ search, ids }) {
			const params = {};
			if (search)
				params.q = search;

			if (ids)
				params.ids = ids;

			const currentList = this.activeIngredients;
			const activeIngredientsResponse = await ActiveIngredientPresenter.findAll(params);

			const responseIds = activeIngredientsResponse.items.map(i => i.id);
			const itemsNotInResponseList = currentList.filter(c => !responseIds.includes(c.id));
			this.activeIngredients = activeIngredientsResponse.items.concat(itemsNotInResponseList);
		},
		async getPbms() {
			const pbmsResponse = await PbmPresenter.findAll();
			this.pbms = pbmsResponse.items?.map(i => ({ ...i, type: pbmTypeLabel[i.type] }));
		},
		async getRoutesAdministration() {
			const routesAdministrationResponse = await RouteAdministrationPresenter.findAll();
			this.routesAdministration = routesAdministrationResponse.items;
		},
		async getAtcsClassification() {
			const atcsClassificationResponse = await AtcClassificationPresenter.findAll();
			this.atcsClassification = atcsClassificationResponse.items;
		},
		async closeCreatePbmDialog() {
			await this.getPbms();
			this.openCreatePbmDialog = false;
		},
		getReferenceDrugs: debounce(async function ({ paginate = true }) {
			const params = { q: this.referenceDrug.term, type: 'REFERENCIA' };

			if (paginate)
				params.perPage = 25;

			this.referenceDrug.loading = true;

			const refereceDrugsResponse = await DrugPresenter.list(params);
			this.referenceDrug.items = [...refereceDrugsResponse.items.map(item => ({
				id: item.drug.id,
				name: `${item.name} ${item.presentation}`
			}))];
			this.referenceDrug.loading = false;
		}, 500),

		async getPrescriptionRules() {
			this.prescriptionRules.loading = true;

			const prescriptionRules = await PrescriptionRulesPresenter.list();
			this.prescriptionRules.items = prescriptionRules.items;
			this.prescriptionRules.loading = false;
		},
		addIngredient() {
			this.$emit('addIngredient');
		},
		removeIngredient(index) {
			this.$emit('removeIngredient', index);
		},
		async validate() {
			return Boolean(await this.$validator.validateAll());
		}
	},
	watch: {
		'referenceDrug.term': async function () {
			await this.getReferenceDrugs({});
		},
		'form.properties': {
			deep: true,
			handler(properties) {
				if (properties.isLiquid) {
					this.form.properties.isDivisible = false;
					this.form.properties.isOutOfBox = true;
				}
				if (!properties.isOutOfBox) {
					this.form.properties.isDivisible = true;
					this.form.properties.isLiquid = false;
				}
				if (properties.isDivisible)
					this.form.properties.isLiquid = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
	.v-input__append-outer {
		margin: 0 !important;
	}
</style>
