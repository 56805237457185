<template>
	<Dialog titulo="Validação dos itens" :subtitulo="order.customer.name" btn_acao="Enviar para faturamento" :carregando="loadingProducts" :btn_acao_disable="btnActionDisable"
		@acao="validateProducts"
		@close="$emit('close')" >
		<v-container grid-list-md class="pa-0">
			<v-layout wrap>
				<template v-if="order.products.length > 0">
					<v-flex xs6>
						<div  v-if="isPickingWithTag" class="label-input">
							<h4 v-if="!withoutLabel" class="mb-2" >
								Utilize um leitor de código de barras ou insira os dados da <b>etiqueta</b> manualmente
							</h4>
							<InputLabel
								v-if="!withoutLabel"
								:label="label"
								:labelIsRequired="isPickingWithTagRequired"
								:withoutLabel="withoutLabel"
								:focusEnabled="initFocusLabel"
								ref="labelRef"
								@filled="val => fillLabel(val)" />
							<div v-if="!isPickingWithTagRequired">
								<v-checkbox
									primary
									hide-details
									v-model="withoutLabel"
									label="Este medicamento não tem etiqueta."/>
							</div>
							<hr v-if="!withoutLabel" class="my-2">
						</div>
						<InputCodigoBarrasPedidos
							ref="eanRef"
							:label="label"
							:withoutLabel="withoutLabel"
							:labelIsRequired="isPickingWithTagRequired"
							:focusEnabled="initFocusEan"
							@mostrarMsg="val => mostrarMsg = val"
							@productPicked="gtin => addItem(gtin)"/>
						<Product :product="productSelected" />
					</v-flex>
					<v-flex xs6>
						<ProductList :products="listProducts" />
					</v-flex>
				</template>
				<v-flex v-else xs12 class="estoque-suficiente ma-4">
					<h2>Produtos suficientes</h2>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog persistent v-model="dialogPickingConfirmed" scrollable transition="fade" max-width="600px">
			<Dialog v-if="dialogPickingConfirmed"
				titulo="Enviar para faturamento" btn_acao="Confirmar" btn_acao_class="primary" btn_cancelar="Cancelar"
				@close="reset" @acao="sendToBilling">
				<p class="text-center my-2 font-weight-medium">Confirmo ter efetuado a separação dos produtos solicitados e ter realizado o picking de forma precisa no sistema. <br> Estou ciente de que estou concluindo o processo e encaminhando-o para a etapa de faturamento, e que não é possível retroceder ou modificar o pedido a partir deste ponto.</p>
			</Dialog>
		</v-dialog>
	</Dialog>
</template>

<script>
import { ProductPresenter } from '@Presenter/product-presenter';
import { validateProcessIsAllowed } from '@Util/order-process';
import { ProcessTypes } from '@Consts/status-order-process';
import { featureFlags } from '@Consts/feature-flags';
import InputLabel from './input-label.vue';
import InputCodigoBarrasPedidos from './input-codigo-barras.vue';
import ProductList from './product-list.vue';
import Product from './product.vue';

export default {
	name: 'DialogBipeOrders',
	components: {
		InputCodigoBarrasPedidos, InputLabel, Product, ProductList
	},
	props: {
		order: {
			type: Object,
			required: true
		},
		changeStatusProcess: {
			type: Function
		},
		typeProcess: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			dialogPickingConfirmed: false,
			listProducts: [],
			productSelected: {},
			loadingProducts: false,
			disableActionButton: false,
			disableBtnByProccess: true,
			label: null,
			withoutLabel: false,
			initFocusEan: false,
			initFocusLabel: false,
			labels: []
		};
	},
	created() {
		this.loadingProducts = true;
		const productIds = this.order.products.map(prodt => prodt.externalId);
		ProductPresenter.getProducts({ ids: productIds })
			.then(response => {
				this.listProducts = this.order.products.map(product => ({
					...product,
					gtin: response.items.find(item => item.id === Number(product.externalId)).gtin,
					enteredQuantity: 0
				}));
			})
			.catch(() => {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao buscar produtos do pedido', color: 'error' });
				this.disableActionButton = true;
			})
			.finally(() => {
				this.loadingProducts = false;
			});

		this.getProcessByOrderAndProcessType(this.order.id);
		this.initFocus();
	},
	beforeDestroy() {
		this.listProducts = [];
	},
	computed: {
		productsInsufficient() {
			return this.listProducts.some(product => product.enteredQuantity < product.quantity);
		},
		productsMoreThanNecessary() {
			return this.listProducts.some(product => product.enteredQuantity > product.quantity);
		},
		btnActionDisable() {
			return !this.order.products.length || this.disableActionButton || this.disableBtnByProccess;
		},
		isPickingWithTag() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.picking_orders_with_tag);
		},
		isPickingWithTagRequired() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.picking_orders_with_tag_required);
		}
	},
	methods: {
		initFocus() {
			if (this.isPickingWithTag)
				this.inputFocusLabel();
			else {
				this.withoutLabel = true;
				this.inputFocusEan();
			}
		},
		medicinePicked() {
			this.label = null;
			this.inputFocusLabel();
		},
		inputFocusLabel() {
			if (this.isPickingWithTag)
				this.withoutLabel = false;

			this.initFocusLabel = true;
			this.initFocusEan = false;
		},
		inputFocusEan() {
			this.initFocusEan = true;
			this.initFocusLabel = false;
		},
		fillLabel(value) {
			this.inputFocusEan();
			this.label = value;
		},
		addItem(gtin) {
			if (!this.withoutLabel && !this.label) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Informe a etiqueta' });
				return;
			}

			this.productSelected = this.listProducts.find(prodt => prodt.gtin === gtin);
			if (!this.productSelected) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Produto não encontrado no pedido. Por favor, verifique se o produto está correto.' });
				this.label = null;
				this.initFocus();
				return;
			}

			if (this.label) {
				this.labels.push({
					ean: gtin,
					etiqueta: this.label
				});
			}

			this.label = null;
			this.productSelected.enteredQuantity += 1;
			this.initFocus();
		},
		validateProducts() {
			this.dialogPickingConfirmed = true;
		},
		reset() {
			this.dialogPickingConfirmed = false;
			this.initFocus();
		},
		sendToBilling() {
			if (this.productsInsufficient) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'Um ou mais produtos estão com a quantidade abaixo do necessário', color: 'error' });
				return;
			}

			if (this.productsMoreThanNecessary) {
				this.$store.dispatch('SHOW_SNACKBAR', { message: 'Um ou mais produtos estão com a quantidade acima do necessário', color: 'error' });
				return;
			}

			validateProcessIsAllowed(this.order.id, ProcessTypes.PICKING)
				.then(isValid => {
					if (isValid) {
						if (this.isPickingWithTag && this.labels.length > 0) {
							this.$emit('productsPicked', { etiquetas: this.labels });
							return;
						}

						this.changeStatusProcess()
							.then(() => {})
							.finally(() => {
								this.close();
							});
					} else {
						this.disableBtnByProccess = !isValid;
						this.reset();
					}
				});
		},
		close() {
			this.$emit('close');
		},
		getProcessByOrderAndProcessType(orderId) {
			this.disableBtnByProccess = true;
			validateProcessIsAllowed(orderId, ProcessTypes.PICKING)
				.then(isValid => {
					this.disableBtnByProccess = !isValid;
				});
		}
	}
};
</script>
