<template>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="290">
		<template v-slot:activator="{ on }">
			<v-btn fab small color="#E500001A" v-on="on" @click.stop="open()">
				<v-icon color="error">delete</v-icon>
			</v-btn>
		</template>

		<section class="farme-dialog">
			<header class="farme-dialog-header">
				Remover medicamento
			</header>

			<main class="farme-dialog-body">
				Deseja remover o medicamento da lista?
			</main>

			<footer class="farme-dialog-footer">
				<v-spacer></v-spacer>
				<v-btn color="" flat @click="cancel">Cancelar</v-btn>
				<v-btn color="primary"  @click="confirm">Sim, remover!</v-btn>
			</footer>
		</section>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false
		};
	},
	methods: {
		open() {
			this.dialog = true;
		},
		close() {
			this.dialog = false;
		},
		cancel() {
			this.close();
		},
		confirm() {
			this.close();
			this.$emit('confirmed', true);
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
