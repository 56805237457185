<template>
	<section>
		<v-flex shrink style="display:flex;flex-direction:column;justify-content:space-between">
			<div class="img-container q-row justify-center text-center">
				<v-btn
					v-if="canDelete"
					small
					icon
					@click="openedDeletePrescriptionDialog = true"
					class="purple delete-btn">
					<v-icon small color="white">delete</v-icon>
				</v-btn>
				<v-icon
					v-if="prescription.url.includes('.pdf')"
					class="pointer pa-3"
					:class="{ 'selecionada': selected }"
					style="border: 2px solid #BCBCBC;"
					@click="$emit('viewPrescription', prescriptionIndex)"
					size="45"
					color="purple">fas fa-file-pdf</v-icon>
				<img
					v-else
					class="pointer pa-3"
					:class="{ 'selecionada': selected }"
					style="border: 2px solid #BCBCBC;"
					:src="prescription.url"
					@click="$emit('viewPrescription', prescriptionIndex);"
					alt="Receita"
					width="80"
					height="80"/>
			</div>
			<v-tooltip top class="text-center">
				<span slot="activator" >
					ID {{ prescription.id }}
				</span>
				<span>
					{{ prescription.name.substring(0, 15) }}
				</span>
			</v-tooltip>
		</v-flex>

		<v-dialog
			persistent
			scrollable
			v-model="openedDeletePrescriptionDialog"
			transition="fade"
			max-width="340px">
			<transition>
				<Dialog
					v-if="openedDeletePrescriptionDialog"
					titulo="Remover receita"
					subtitulo="Esta ação não pode ser desfeita"
					btn_acao="Remover"
					btn_acao_class="error"
					btn_cancelar="Cancelar"
					@acao="deletePrescription"
					@close="openedDeletePrescriptionDialog = false"/>
			</transition>
		</v-dialog>
	</section>

</template>

<script>
export default {
	props: {
		prescriptionIndex: {
			required: true,
			type: Number
		},
		prescription: {
			required: true,
			type: Object
		},
		selected: {
			required: false,
			default: false
		},
		canDelete: {
			required: false,
			default: false
		}
	},
	data() {
		return {
			openedDeletePrescriptionDialog: false
		};
	},
	methods: {
		abrirDialog(data, dialog) {
			console.log('open dialog', { data, dialog });
		},
		deletePrescription() {
			this.$emit('deletePrescription', this.prescription.id);
			this.openedDeletePrescriptionDialog = false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.img-container {
	position: relative;

	.delete-btn {
		opacity: 0;
		transition: opacity 0.3s;
	}

	&:hover {
		.delete-btn {
			opacity: 1;
		}
	}

	button {
		position: absolute;
		right: 0;
	}

	img, i.v-icon {
		&.selecionada {
			border: 2px solid $primary!important;
		}
	}
}
</style>
