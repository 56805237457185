<template>
	<v-dialog
		persistent
		scrollable
		v-model="dialog"
		transition="fade"
		max-width="600px">

		<template v-slot:activator="{ on }">
			<v-btn
				fab
				small
				:color=" hasPause ? '#8B8B8B33' : 'white'"
				v-on="on"
				@click.stop="open()">
				<v-icon color="secondary">
					{{ hasPause ? 'fas fa-play' : 'pause' }}
				</v-icon>
			</v-btn>
		</template>

		<PauseMedicineDialog
			:e_rascunho="true"
			:medicamento_selecionado="item"
			:data_base_pausa="initialDateToPause"
			:medicamento_pausado="!!item.pausa"
			@close="close"
			@pausa-adicionada="pauseChanged"
			@pausa-removida="pauseChanged"
			@pausa-editada="pauseChanged"/>

		<!--
		<template v-if="hasPause">
			<EditPauseBody
				:pause="item.pause"
				@cancelled="close"
				@removePause="removePause"
				@updatePause="updatePause"/>
		</template>

		<template v-else>
			<CreatePauseBody
				@cancelled="close"
				@addPause="addPause"/>
		</template>
		-->
	</v-dialog>
</template>

<script>
import PauseMedicineDialog from '@Componentes/medicamento/dialog-medicamento/dialog-pausar-medicamento';

export default {
	components: {
		PauseMedicineDialog
	},
	props: {
		item: {
			required: true,
			type: Object
		},
		initialDateToPause: {
			required: false
		}
	},
	data() {
		return {
			dialog: false,
			form: {
				cycles: 3,
				reason: 'Produto fornecido fora da Far.me'
			}
		};
	},
	computed: {
		hasPause() {
			return !!this.item.pausa;
		}
	},
	methods: {
		open() {
			this.dialog = true;
		},
		close() {
			this.dialog = false;
		},
		pauseChanged() {
			this.$emit('pauseChanged');
			this.close();
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
