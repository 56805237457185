<template>
	<section>
		<v-layout wrap class="pa-3 white">
			<PrescriptionCard
				v-for="(prescription, index) of prescriptions"
				:key="index"
				:prescriptionIndex="index"
				:prescription="prescription"
				:selected="prescriptionSelectedIndex === index"
				:canDelete="canDelete"
				@viewPrescription="viewPrescription"
				@deletePrescription="deletePrescription" />
		</v-layout>
		<v-dialog
			v-model="openedShowPrescriptionsDialog"
			scrollable
			transition="fade"
			fullscreen>
			<transition>
				<ViewPrescriptionsDialog
					v-if="openedShowPrescriptionsDialog"
					:receitas="prescriptions"
					:index="prescriptionSelectedIndex"
					@close="openedShowPrescriptionsDialog = false"/>
			</transition>
		</v-dialog>
	</section>
</template>

<script>
import ViewPrescriptionsDialog from '@Componentes/assinatura/dialog-visualizar-receitas.vue';
import axios from 'axios';
import { openBuffer } from '@Util/functions';
import PrescriptionCard from './prescription-card.vue';

export default {
	components: {
		PrescriptionCard,
		ViewPrescriptionsDialog
	},
	props: {
		prescriptions: {
			required: false,
			type: Array,
			default: () => ([])
		},
		canDelete: {
			required: false,
			default: false
		}
	},
	data() {
		return {
			prescriptionSelectedIndex: 0,
			openedShowPrescriptionsDialog: false
		};
	},
	methods: {
		isPdf(prescription) {
			return prescription.url.includes('.pdf');
		},
		viewPrescription(index) {
			this.prescriptionSelectedIndex = index;
			const prescription = this.prescriptions[index];
			if (this.isPdf(prescription))
				this.openPdf(prescription);
			else
				this.openedShowPrescriptionsDialog = true;
		},
		deletePrescription(id) {
			this.$emit('deletePrescription', id);
		},
		openPdf(receita) {
			axios.get(receita.url, { responseType: 'arraybuffer' })
				.then(response => openBuffer(response.data))
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao abrir PDF' });
				})
				.finally(() => {
				});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
