<template>
	<v-card style="height: 100%;display: flex;flex-direction: column;justify-content: space-between;" :class="{'border-concluded': isFinished}">
		<v-card-title class="pb-0">
			<h4 class="font-weight-bold mb-3">
				{{ getTitle }}
			</h4>
			<hr style="height: 3px;border: none; width: 100%" :class="[isFinished ? 'success' : 'primary']">
		</v-card-title>

		<v-card-text>
			<section class="overflow mb-1">
				{{ pendence.descricao }}
			</section>

			<section class="caption d-flex grey--text">
				{{ pendence.openedBy.nome }}

				<v-spacer></v-spacer>

				{{ pendence.created_at | formatDate('DD/MM/YYYY HH:mm') }}
			</section>
		</v-card-text>

		<v-card-text>
			<v-dialog
				v-if="!isFinished"
				v-model="dialog"
				persistent
				max-width="290">
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						color="primary"
						@click.stop="dialog = true"
						block>
						Marcar como concluído
					</v-btn>
				</template>

				<section class="farme-dialog">
					<header class="farme-dialog-header">
						Concluir pendência
					</header>

					<main class="farme-dialog-body">
						Você está concluíndo essa pendência, tem certeza?
					</main>

					<footer class="farme-dialog-footer">
						<v-spacer></v-spacer>
						<v-btn color="" flat @click="handle()">Cancelar</v-btn>
						<v-btn color=""  @click="handle(true)">Concluir</v-btn>
					</footer>
				</section>
			</v-dialog>

			<section v-else>
				<h5 class="success--text">Concluído por:</h5>
				<section class="caption font-weight-bold d-flex">
					{{ pendence.concludedBy.nome }}
					<v-spacer></v-spacer>
					{{ pendence.concluded_at | formatDate('DD/MM/YYYY HH:mm') }}
				</section>
			</section>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		position: {
			required: false,
			type: Number
		},
		pendence: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			dialog: false
		};
	},
	computed: {
		getTitle() {
			const category = this.pendence.categoria;
			if (!this.position)
				return category;
			return `${this.position} - ${category}`;
		},
		isFinished() {
			return this.pendence.status === 'CONCLUDED';
		}
	},
	methods: {
		handle(finish = false) {
			if (finish)
				this.$emit('finish', this.pendence.id);
			this.dialog = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.border-concluded {
	border: 2px solid #2FB06A!important;

}
.overflow {
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
</style>
