<template>
	<v-container fluid grid-list-md class="pa-0">
		<section>
			<div class="header">
				<h3>Descontos no ciclo</h3>
			</div>
		</section>

		<v-layout wrap class="mt-4">
			<v-flex md2 xs12>
				<v-autocomplete
					box
					clearable
					v-model="form.type"
					:items="options"
					item-value="value"
					item-text="text"
					no-data-text="Nenhuma forma de responsabilidade encontrada"
					background-color="white"
					label="Tipo de desconto"
					data-vv-name="Tipo de desconto"
					v-validate="{ required: false }"
					:error-messages="errors.collect('Tipo de desconto')"
					@change="() => form.value = 0"/>
			</v-flex>

			<v-flex md2 xs12>
				<v-text-field
					v-model="form.value"
					v-money="isRealValueDiscountType"
					background-color="white"
					box
					label="Desconto"
					data-vv-name="Desconto"
					v-validate="discountValidations"
					append-icon="percent"
					:error-messages="errors.collect('Desconto')"/>
			</v-flex>

			<v-flex md6 xs12 class="items-center">
				<p class="ml-md-4">
					Será aplicado um desconto de <strong>{{ discount }}</strong> neste ciclo. <br>
					O desconto será distribuído proporcionalmente nos medicamentos dentro da Box.
				</p>
			</v-flex>
			<v-flex md2 xs12 class="items-center justify-end">
				<v-btn
					v-if="isUpdated"
					color="primary"
					:loading="loading"
					:disabled="!formFilled || loading"
					@click="applyDiscount">
					Atualizar
				</v-btn>

				<v-btn
					v-if="canDelete"
					color="error"
					:loading="loading"
					@click="removeDiscount">
					Remover
				</v-btn>

			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { DISCOUNT_AMOUNT_TYPE, DISCOUNT_PERCENTAGE_TYPE, DISCOUNT_TYPES } from '@Consts/discount-options';
import { mascaraDinheiro } from '@Util/functions';

export default {
	name: 'DraftDialogDiscountSection',
	inject: ['$validator'],
	props: {
		discountValue: {
			required: false,
			type: Number,
			default: 0
		},
		discountType: {
			required: false,
			default: null
		}
	},
	created() {
		this.form.value = this.discountValue;
		this.form.type = this.discountType;
	},
	data() {
		return {
			loading: false,
			form: {
				value: null,
				type: null
			}
		};
	},
	computed: {
		options() {
			return DISCOUNT_TYPES.map(option => ({ text: option.text, value: option.value }));
		},
		isUpdated() {
			return (this.form.value !== this.discountValue) || (this.form.type !== this.discountType);
		},
		formFilled() {
			return this.form.value && this.form.type;
		},
		canDelete() {
			return this.discountValue && this.discountType;
		},
		isPercentageDiscountType() {
			return this.form.type && this.form.type === DISCOUNT_PERCENTAGE_TYPE.value;
		},
		isRealValueDiscountType() {
			return this.form.type && this.form.type === DISCOUNT_AMOUNT_TYPE.value;
		},
		discountValidations() {
			const validations = {
				required: true

			};
			if (this.isPercentageDiscountType) {
				validations.gte = 0;
				validations.lte = 100;
			}
			return validations;
		},
		discount() {
			if (this.isPercentageDiscountType) {
				const value = this.form.value;
				return `${value}${DISCOUNT_PERCENTAGE_TYPE.icon}`;
			}
			if (this.isRealValueDiscountType) {
				const value = Number(this.$functions.onlyNumber(this.form.value));
				return `${mascaraDinheiro(value)}`;
			}
			return '???';
		}
	},
	methods: {
		async validateForm() {
			return Boolean();
		},
		resetForm() {
			this.form.value = null;
			this.form.type = null;
		},
		async applyDiscount() {
			const IsFormValid = await this.$validator.validateAll();
			if (!IsFormValid) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Desconto inválido' });
				return;
			}
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 2000);
			this.$emit(
				'applyDiscount',
				{
					value: Number(this.isRealValueDiscountType ? this.$functions.onlyNumber(this.form.value) : this.form.value * 100),
					type: this.form.type
				}
			);
			this.resetForm();
		},
		removeDiscount() {
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 2000);
			this.$emit('removeDiscount');
			this.resetForm();
		}
	}
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px dashed #3B2C1B80;
	padding-bottom: 10px;

	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 30px;
	}
}
</style>
