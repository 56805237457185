import { versao } from '@Config/versions';
import { featureFlags } from '@Consts/feature-flags';

function buildFs() {
	const keys = {};
	const fsKeys = Object.keys(featureFlags);
	// eslint-disable-next-line no-restricted-syntax
	for (const fs of fsKeys) {
		const fsName = featureFlags[fs];
		keys[fsName] = {
			enabled: false,
			variant: null
		};
	}
	return keys;
}

export const getState = () => ({
	version: versao,
	box_id: null,
	novabox: null,
	contexto: null,
	visao_colaborador: false,
	msg_toast: null,
	snackbar: {
		visible: false,
		message: '',
		color: 'success',
		timeout: 4000
	},
	featureFlags: buildFs()
});
