<template>
	<div class="barcode" :class="{'barcode-error': barcodeError}">
		<input
			v-model="labelInput"
			ref="labelRef"
			@keyup.enter="enter"
			@blur="enter"
			@input="validateBarcode">
	</div>
</template>

<script>

export default {
	name: 'InputLabel',
	props: {
		label: {
			type: String
		},
		withoutLabel: {
			type: Boolean
		},
		focusEnabled: {
			required: false,
			type: Boolean,
			default: false
		},
		labelIsRequired: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		labelInput: null,
		barcodeError: false
	}),
	mounted() {
		if (this.focusEnabled)
			this.inputFocus();
	},
	watch: {
		focusEnabled(value) {
			if (value)
				this.inputFocus();
		},
		label(value) {
			this.labelInput = value;
		}
	},
	methods: {
		validateBarcode() {
			if (!this.withoutLabel && this.labelInput.length !== 8) {
				this.barcodeError = true;
				this.inputFocus();
			} else
				this.barcodeError = false;
		},
		inputFocus() {
			if (this.$refs.labelRef)
				this.$refs.labelRef.focus();
		},
		enter() {
			if (this.labelIsRequired && !this.labelInput) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error', message: 'Obrigatório informar a etiqueta'
				});
				return;
			}

			if (!this.barcodeError)
				this.$emit('filled', this.labelInput);
			else {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error', message: 'Formato da etiqueta errado'
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.barcode {
	border-radius: 10px;
	background: url("../../processo/picking/dialog-bipe/barcode.png");

	input {
		text-align: center;
		font-weight: 900;
		font-size: 24px;
		letter-spacing: 1px;
		height: 60px;
		outline: none;
		width: 100%;
	}
}

.barcode-error{
	border: solid 2px red;
}
</style>
