<template>
	<section>
		<v-data-table
			:items="tableItems"
			:headers="headers"
			v-model="selected"
			:loading="loading"
			:total-items="total"
			class="custom-data-table"
			hide-actions
			:disable-initial-sort="true"
			:pagination.sync="pagination"
			rows-per-page-text="Items por página"
			:rows-per-page-items="itemsPerPageOptions"
			no-results-text="Nenhum resultado encontrado"
			:no-data-text="loading ? 'Carregando...' : 'Nenhum intem encontrado'">
			<template v-slot:headers="props">
				<tr>
					<th width="10px">
						<v-checkbox
							color="primary"
							:input-value="props.all"
							:indeterminate="props.indeterminate"
							hide-details
							@click.stop="toggleAll"></v-checkbox>
					</th>
					<th
						v-for="header in props.headers"
						:key="header.text"
						:width="header.width"
						class="column"
						:class="[
							header.sortable ? 'sortable' :  '',
							pagination.descending ? 'desc' : 'asc',
							header.value === pagination.sortBy ? 'active' : ''
						]"
						@click="changeSort(header)">
						<v-icon v-if="header.sortable " small>arrow_upward</v-icon>
						{{ header.text }}
					</th>
				</tr>
			</template>

			<template v-slot:items="props">
				<tr :active="props.selected" :style="`background-color: ${getBackgroundColorRow(props.item)};`">
					<td @click="props.selected = !props.selected">
						<v-checkbox
							v-if="!isDeleted(props.item)"
							:input-value="props.selected"
							primary
							hide-details></v-checkbox>
					</td>
					<td class="text-left">
						{{ props.item.id }}
					</td>
					<td class="text-left">
						<section style="width: 180px">
							<strong>{{ props.item.name }}</strong> <br>
							{{ props.item.presentation }}
						</section>
					</td>
					<td class="text-center">
						<p style="width: 200px;">{{ props.item.activeIngredient }}</p>
					</td>
					<td class="text-center">
						{{ props.item.type }}
					</td>

					<td class="text-center">
						<div style="min-width: 200px;">
							<strong>{{ getFrequencyPosology(props.item.posology) }}</strong> <br>

							<template v-if="getDetailsFromPosologyFrequence(props.item.posology)">
								{{ getDetailsFromPosologyFrequence(props.item.posology) }}
							</template>
						</div>
					</td>
					<td class="text-left">
						<ul style="list-style: none;width: 200px;">
							<li v-for="(hour, index) of getItemsPosology(props.item.posology)" :key="index">{{ hour }}</li>
						</ul>
					</td>
					<td class="text-center">{{ props.item.typeOfPrescription || '---' }}</td>
					<td class="text-center">{{ !!props.item.isPbm ? 'Sim' : 'Não' }}</td>
					<td class="text-center">{{ getStock(props.item) }}</td>
					<td class="text-center">{{ getFinalPrice(props.item) | dinheiro }}</td>
					<td class="text-center">
						<PrescriptionReuseCycle
							v-if="props.item.isControlled"
							style="width: 130px;"
							:desabilitado="isDeleted(props.item) || isPaused(props.item)"
							:eRascunho="true"
							:boxId="getOriginalItem(props.item.id).box.id"
							:medicamentoId="props.item.medicineId"
							:quantidade="props.item.prescriptionCycles" />
						<span v-else>---</span>
					</td>
					<td class="text-center text-wrap" >
						<p style="width: 200px!important;">{{ getAdditionalInfo(props.item) }}</p>
					</td>
					<!--
					<td class="text-center">
						<PrescriptionAttachment
							style="width: 200px;"
							:isDivisible="true"
							class="my-3"
							:isControlled: !!med.medicamento.portaria_344_98,="false"
							:prescriptions="[]"
							:boxId="boxId"
							:medicineId="props.item.id"
							:selectedPrescriptionId="[]"
							:estoqueMedicine="getStock(props.item)"
							:neededPillsQuantity="0"
							:readonly="false"
							:isVisible="true"
							:medicineIsPaused="!!getOriginalItem(props.item.id).pausa"
							@receita-vinculada="$emit('receita-vinculada', true)"/>
					</td>
					-->
					<td>
						<div style="display: flex;justify-content: end;align-items: center;">
							<EditItemDialog
								v-if="canEditItem(props.item)"
								:item="getOriginalItem(props.item.id)"
								@editItem="editItem" />

							<PauseItemDialog
								v-if="canPauseItem(props.item)"
								:item="getOriginalItem(props.item.id)"
								:initialDateToPause="initialDateToPause"
								@pauseChanged="pauseChanged"/>

							<DeleteItemDialog
								v-if="canDeleteItem(props.item)"
								@confirmed="deleteItem(props.item.id)" />
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>
	</section>
</template>

<script>
import { PERIODO_DE_USO, PERIODO_DE_USO_LABEL } from '@Consts/posologia';
import { formatDate, getHorario } from '@Util/functions';

// import PrescriptionAttachment from '@Componentes/medicamento/vincula-receita/index.vue';
import PrescriptionReuseCycle from '@Componentes/medicamento/medicamento-card/aproveitamento-receita.vue';
import EditItemDialog from './actions/edit-item-dialog';
import PauseItemDialog from './actions/pause-item-dialog/index.vue';
import DeleteItemDialog from './actions/delete-item-dialog/index.vue';

export default {
	name: 'ListProductionTable',
	components: {
		EditItemDialog,
		PauseItemDialog,
		DeleteItemDialog,
		// PrescriptionAttachment,
		PrescriptionReuseCycle
	},
	props: {
		loading: {
			required: false,
			type: Boolean,
			default: false
		},
		items: {
			required: false,
			type: Array,
			default: () => ([])
		},
		total: {
			required: false,
			type: Number,
			default: 0
		},
		itemsPerPageOptions: {
			required: false,
			type: Array,
			default: () => ([5, 10, 25, 100])
		},
		itemsPerPage: {
			required: false,
			type: Number,
			default: 5
		},
		isPrescriptionCycleVisible: {
			required: false,
			type: Boolean,
			default: false
		},
		stock: {
			required: false,
			type: Array,
			default: () => ([])
		},
		initialDateToPause: {
			required: false
		}
	},
	data() {
		return {
			generatingTags: false,
			boxId: null,
			dialogChoiceMedicinesOpened: false,
			pagination: {
				rowsPerPage: -1
			},
			selected: []
		};
	},
	computed: {
		tableItems() {
			// return this.items;
			return this.items.map(med => ({
				id: med.alteracao_id,
				medicineId: med.medicamento_id,
				name: med.medicamento.produto,
				presentation: med.medicamento.apresentacao,
				activeIngredient: med.medicamento.principio_ativo,
				type: med.medicamento.descricao_tipo_produto,
				isNew: !med.assinatura_medicamento_id,
				isPbm: !!med.medicamento.pbm,
				isDivisible: !med.medicamento.liquido,
				isControlled: !!med.medicamento.portaria_344_98,
				pause: med.pausa
					? {
						cycles: med.pausa.ciclos,
						reason: med.pausa.motivo
					} : null, // verificar data?
				posology: med.posologia,
				stock: med.estoque,
				typeOfPrescription: med.medicamento.portaria_344_98,
				price: med.preco_personalizado,
				discount: med.desconto,
				discountInCycle: med.desconto_no_ciclo || null,
				prescriptionCycles: med.ciclos_extras || 1,
				prescriptions: med.medicamento.receitas,
				createdAt: med.created_at,
				updatedAt: med.updated_at,
				deletedAt: med.deleted_at
			}));
		},
		headers() {
			return [
				{
					text: 'ID',
					value: 'header-id',
					sortable: false
				},
				{
					text: 'Medicamento',
					value: 'header-medicine',
					sortable: false,
					width: '300px'
				},
				{
					text: 'Princípio ativo',
					value: 'header-active-ingredient',
					sortable: false
				},
				{
					text: 'Tipo',
					value: 'header-medicine-type',
					sortable: false
				},
				{
					text: 'Frequência',
					value: 'header-period-posology',
					// this property has to be on sortable from pagination
					sortable: false
				},
				{
					text: 'Posologia',
					value: 'header-posology',
					width: '10px',
					sortable: false
				},
				{
					text: 'Tipo de receita',
					value: 'header-prescription-type',
					// this property has to be on sortable from pagination
					sortable: false
					// width: '200px'
				},
				{
					text: 'PBM',
					value: 'header-pbm',
					// this property has to be on sortable from pagination
					sortable: false
					// width: '200px'
				},
				{
					text: 'Estoque',
					value: 'header-stock',
					// this property has to be on sortable from pagination
					sortable: false
					// width: '200px'
				},
				{
					text: 'Valor total',
					value: 'header-amount',
					// this property has to be on sortable from pagination
					sortable: false
					// width: '200px'
				},
				{
					text: 'Aprov. de receita',
					value: 'header-prescription-cycles',
					// this property has to be on sortable from pagination
					sortable: false,
					width: '10px'
				},
				{
					text: 'Info. adicionais',
					value: 'header-info',
					// this property has to be on sortable from pagination
					sortable: false
					// width: '200px'
				},
				/*
				{
					text: 'Receitas',
					value: 'header-prescriptions',
					// this property has to be on sortable from pagination
					sortable: false
					// width: '200px'
				},
				*/
				{
					text: 'Ações',
					sortable: false,
					width: '300px'
				}
			];
		}
	},
	methods: {
		getStock(medicine) {
			if (!medicine.stock)
				return 0;
			if (medicine.isDivisible)
				return medicine.stock.comprimidos;
			return medicine.stock.caixas;
		},
		getBackgroundColorRow(item) {
			if (this.isDeleted(item))
				return '#E500001A';
			if (item.pause)
				return '#E2E2E2';
			if (item.isNew || (item.createdAt !== item.updatedAt))
				return '#DEEEFF';
			return 'white';
		},
		canEditItem(item) {
			return !this.isDeleted(item) && !this.isPaused(item);
		},
		canDeleteItem(item) {
			return !this.isDeleted(item);
		},
		canPauseItem(item) {
			return !this.isDeleted(item);
		},
		isActionEnabled(orignalItem) {
			return orignalItem.deleted_at === null;
		},
		isDeleted(item) {
			return !!item.deletedAt;
		},
		isPaused(item) {
			return !!item.pause;
		},
		isUpdated(item) {
			const createdAt = item.createdAt.toString();
			const updatedAt = item.updatedAt.toString();
			return createdAt !== updatedAt;
		},
		getOriginalItem(id) {
			return this.items.find(i => i.alteracao_id === id);
		},
		updateFilters() {
			this.$emit('filter', {
				pagination: {
					page: Number(this.pagination.page),
					perPage: Number(this.pagination.rowsPerPage)
				},
				sorting: {
					field: this.pagination.sortBy,
					direction: this.pagination.descending ? 'DESC' : 'ASC'
				}
			});
		},
		toggleAll() {
			if (this.selected.length)
				this.selected = [];
			else
				this.selected = this.items.slice();
		},
		changeSort(header) {
			if (!header.sortable)
				return;
			const column = header.value;
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		getDetailsFromPosologyFrequence(posology) {
			if (!posology)
				return null;
			const frequency = posology.periodo_de_uso;
			if (frequency === PERIODO_DE_USO.DIAS_DA_SEMANA) {
				const weekDays = posology.dias_da_semana;
				return weekDays.join(', ');
			}
			if (frequency === PERIODO_DE_USO.DIAS_DO_MES) {
				const monthDays = posology.dias_do_mes;
				return monthDays.join(', ');
			}
			if (frequency === PERIODO_DE_USO.OUTRO) {
				const type = posology.outro_periodo.tipo;
				const day = posology.outro_periodo.dia.toLowerCase();
				return `${type} ${day} do mês`;
			}
			return null;
		},
		getFrequencyPosology(posology) {
			return PERIODO_DE_USO_LABEL[posology.periodo_de_uso];
		},
		getItemsPosology(posology) {
			return posology.horarios.map(horario => getHorario(horario));
		},
		getFinalPrice(item) {
			const price = Number(item.price) - Number(item.discount);
			if (this.isDeleted(item))
				return price;
			return price - Number(item.discountInCycle);
		},
		getAdditionalInfo(item) {
			if (this.isDeleted(item))
				return `Excluído em ${formatDate(item.deletedAt, 'DD/MM/YYYY HH:mm')}`;
			if (item.isNew)
				return `Adicionado em ${formatDate(item.createdAt, 'DD/MM/YYYY HH:mm')}`;
			if (this.isPaused(item))
				return `Pausado em ${formatDate(item.updatedAt, 'DD/MM/YYYY HH:mm')}`;
			if (this.isUpdated(item))
				return `Editado em ${formatDate(item.updatedAt, 'DD/MM/YYYY HH:mm')}`;
			return '-';
		},
		updatePrescriptionCycle(medicineId, cycles) {
			this.$emit('updatePrescriptionCycle', { medicineId, cycles });
		},
		deleteItem(draftId) {
			this.$emit('deleteItem', draftId);
		},
		pauseChanged() {
			this.$emit('pauseChanged');
		},
		editItem(data) {
			this.$emit('editItem', data);
		}
	},
	watch: {
		pagination: {
			deep: true,
			handler() {
				this.updateFilters();
			}
		},
		selected: {
			handler() {
				this.$emit('updatedSelected', this.selected);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
header {
	border-bottom-width: 5px;
	border-bottom-color: trans !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.column-overflow {
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

::v-deep .custom-data-table  {
	.v-table {
		background-color: transparent;
		border-collapse: separate;
		border-spacing: 0 5px;

		thead th {
			padding-top: 10px;
			padding-bottom: 10px;
			height: 100%;
		}
		thead {
			tr {
				background-color: transparent;
			}

			th {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid transparent;
				}

				&:last-child {
					border: 4px solid transparent;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}
		tbody {

			tr {
				background-color: transparent;
			}

			td {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid inherit;
				}

				&:last-child {
					border: 4px solid inherit;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}

	}

	.v-datatable {
		.v-datatable__actions {
			background-color: transparent;
		}
	}
}
</style>
