export const frequencies = [
	{ value: 1, label: 'Todos os dias' },
	{ value: 2, label: 'Dia sim, dia não' },
	{ value: 3, label: 'Dia não, dia sim' },
	{ value: 4, label: 'Dias da semana' },
	{ value: 5, label: 'Dias do mês' }
];

export const posologyTypes = {
	allDays: 1,
	dayYesDayNot: 2,
	dayNotDayYes: 3,
	weekly: 4,
	monthly: 5
};

export const quantityOfBoxes = [
	{ value: 1, label: '1 caixa' },
	{ value: 2, label: '2 caixas' },
	{ value: 3, label: '3 caixas' },
	{ value: 4, label: '4 caixas' },
	{ value: 5, label: '5 caixas' },
	{ value: 6, label: '6 caixas' },
	{ value: 7, label: '7 caixas' },
	{ value: 8, label: '8 caixas' },
	{ value: 9, label: '9 caixas' },
	{ value: 10, label: '10 caixas' }
];

export const quantityPerHour = [
	{ value: 0.5, label: 'meio' },
	{ value: 1, label: '1' },
	{ value: 1.5, label: '1 e meio' },
	{ value: 2, label: '2' },
	{ value: 2.5, label: '2 e meio' },
	{ value: 3, label: '3' },
	{ value: 3.5, label: '3 e meio' },
	{ value: 4, label: '4' },
	{ value: 4.5, label: '4 e meio' },
	{ value: 5, label: '5' },
	{ value: 5.5, label: '5 e meio' },
	{ value: 6, label: '6' },
	{ value: 6.5, label: '6 e meio' },
	{ value: 7, label: '7' },
	{ value: 7.5, label: '7 e meio' },
	{ value: 0.333, label: '1/3' },
	{ value: 0.666, label: '2/3' },
	{ value: 0.25, label: '1/4' },
	{ value: 0.75, label: '3/4' }
];

export const daysOfWeek = [
	{ label: 'Segunda', active: false },
	{ label: 'Terça', active: false },
	{ label: 'Quarta', active: false },
	{ label: 'Quinta', active: false },
	{ label: 'Sexta', active: false },
	{ label: 'Sábado', active: false },
	{ label: 'Domingo', active: false }
];

export const genders = [
	{ id: 'FEMALE', gender: 'Feminino' },
	{ id: 'MALE', gender: 'Masculino' }
];

export const stepsQuotation = Object.freeze({
	generalData: 'general-data',
	products: 'products',
	clientData: 'client-data'
});

export const allQuotationStatus = {
	UNDER_ANALYSIS: {
		label: 'Em análise',
		class: 'warning',
		value: 'UNDER_ANALYSIS'
	},
	UNDER_REVISION: {
		label: 'Em revisão',
		class: 'bg-blue',
		value: 'UNDER_REVISION'
	},
	APPROVED: {
		label: 'Aprovado',
		class: 'success',
		value: 'APPROVED'
	},
	DONE: {
		label: 'Finalizado',
		class: 'finalized',
		value: 'DONE'
	},
	EXPIRED: {
		label: 'Expirado',
		class: 'expired',
		value: 'EXPIRED'
	},
	CANCELED: {
		label: 'Cancelado',
		class: 'canceled',
		value: 'CANCELED'
	},
	SUBMITTED: {
		label: 'Submetido',
		class: 'bg-blue',
		value: 'SUBMITTED'
	},
	DRAFT: {
		label: 'Rascunho',
		class: 'draft',
		value: 'DRAFT'
	},
	PAID: {
		label: 'Pago',
		class: 'paid',
		value: 'PAID'
	},
	CREATED: {
		label: 'Pedido Criado',
		class: 'success',
		value: 'CREATED'
	}
};

export const quotationPaymentModes = {
	INTEGRAL: 'INTEGRAL',
	PROPORTIONAL: 'PROPORTIONAL'
};
