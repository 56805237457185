export const pbmType = Object.freeze({
	PHARMALINK: 'PHARMALINK',
	VIDA_LINK: 'VIDA_LINK',
	TRN_CENTER: 'TRN_CENTER',
	FARMACIA_POPULAR: 'FARMACIA_POPULAR',
	EPHARMA: 'EPHARMA',
	FUNCIONAL_CARD: 'FUNCIONAL_CARD',
	PREV_SAUDE: 'PREV_SAUDE',
	FARMA_SEG: 'FARMA_SEG',
	GLOBAL_SAUDE: 'GLOBAL_SAUDE',
	ULTRAMAX: 'ULTRAMAX',
	DROGA_BELLA: 'DROGA_BELLA',
	LINX: 'LINX',
	COMUNIX: 'COMUNIX',
	DERMA_CLUB: 'DERMA_CLUB',
	QUANTUM: 'QUANTUM',
	INTERPLAYERS_VAREJO: 'INTERPLAYERS_VAREJO'
});

export const pbmTypeLabel = Object.freeze({
	[pbmType.PHARMALINK]: 'Pharma Link',
	[pbmType.VIDA_LINK]: 'Vida link',
	[pbmType.TRN_CENTER]: 'TRN Center',
	[pbmType.FARMACIA_POPULAR]: 'Farmácia popular',
	[pbmType.EPHARMA]: 'EPharma',
	[pbmType.FUNCIONAL_CARD]: 'Funcional Card',
	[pbmType.PREV_SAUDE]: 'Prev Saúde',
	[pbmType.FARMA_SEG]: 'Farma SEG',
	[pbmType.GLOBAL_SAUDE]: 'Global Saúde',
	[pbmType.ULTRAMAX]: 'Ultramax',
	[pbmType.DROGA_BELLA]: 'Droga Bella',
	[pbmType.LINX]: 'Linx',
	[pbmType.COMUNIX]: 'Comunix',
	[pbmType.DERMA_CLUB]: 'Derma Club',
	[pbmType.QUANTUM]: 'Quantum',
	[pbmType.INTERPLAYERS_VAREJO]: 'Interplayers Varejo'
});
