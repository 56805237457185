import { render, staticRenderFns } from "./draft-dialog-footer.vue?vue&type=template&id=6f995c30&scoped=true"
import script from "./draft-dialog-footer.vue?vue&type=script&lang=js"
export * from "./draft-dialog-footer.vue?vue&type=script&lang=js"
import style0 from "./draft-dialog-footer.vue?vue&type=style&index=0&id=6f995c30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f995c30",
  null
  
)

export default component.exports