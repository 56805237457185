<template>
	<section class="">
		<ListOrdersHeader
			:totalBoxes="totalBoxes"
			:totalOrders="totalOrders"
			:selected="selected"
			:viewTypeIsBox="isViewTypeBox"
			@filter="headerFilterParams => updateFilters(headerFilterParams)"
			@retryProcessingIds="ids => retryProcessing(ids)"/>
		<ListOrdersDataTable
			:loading="isLoading"
			:items="items"
			:totalBoxes="totalBoxes"
			:totalOrders="totalOrders"
			:view="filterParams.filter.view"
			@updatedSelected="items => selected = items"
			@filter="tableFilterParams => updateFilters(tableFilterParams)"
			@retryProcessingId="id => retryProcessing([id])"/>
	</section>
</template>

<script>
import { isEqual } from 'lodash';
import { BoxPresenter } from '@Presenter/box-presenter';
import { OrderPresenter } from '@Presenter/order-presenter';
import ListOrdersHeader from './header';
import ListOrdersDataTable from './table.vue';

export default {
	name: 'AcompanhamentoPedidos',
	mixins: [],
	components: { ListOrdersHeader, ListOrdersDataTable },
	data() {
		return {
			isLoading: false,
			filterParams: {
				pagination: {},
				sorting: {},
				filter: {}
			},
			items: [],
			totalBoxes: 0,
			totalOrders: 0,
			selected: []
		};
	},
	created() {
		this.getOrders();
		this.getBoxes();
	},
	computed: {
		isViewTypeBox() {
			return this.filterParams.filter.view === 'BOXES';
		}
	},
	methods: {
		setItems(items) {
			this.items = items;
		},
		updateFilters(filters) {
			this.filterParams = {
				...this.filterParams,
				...filters
			};
		},
		async getBoxes() {
			this.isLoading = true;
			try {
				const boxesResponse = await BoxPresenter.getBoxesInProcess({
					filter: this.filterParams.filter,
					pagination: this.filterParams.pagination,
					sorting: this.filterParams.sorting
				});

				this.totalBoxes = boxesResponse.total;
				if (this.isViewTypeBox)
					this.setItems(boxesResponse.items);
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar dados.', color: 'error' }
				);
			} finally {
				this.isLoading = false;
			}
		},
		async getOrders() {
			this.isLoading = true;
			try {
				const ordersResponse = await OrderPresenter.getOrdersInProcess({
					filter: this.filterParams.filter,
					pagination: this.filterParams.pagination,
					sorting: this.filterParams.sorting
				});
				this.totalOrders = ordersResponse.total;
				if (!this.isViewTypeBox)
					this.setItems(ordersResponse.items);
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar dados.', color: 'error' }
				);
			} finally {
				this.isLoading = false;
			}
		},
		async retryProcessing(ids) {
			try {
				this.isLoading = true;
				await OrderPresenter.retryProcessingOrders({
					ids,
					view: this.filterParams.filter.view
				});
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro no reprocessamento do(s) pedido(s).', color: 'error' }
				);
			} finally {
				this.isLoading = false;
			}
		}
	},
	watch: {
		filterParams: {
			deep: true,
			async handler(newVal, oldVal) {
				if (isEqual(newVal, oldVal))
					return;
				if (this.isViewTypeBox)
					await this.getBoxes();
				else
					await this.getOrders();
			}
		}
	}
};

</script>
<style lang="scss" scoped>
</style>
