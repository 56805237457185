<template>
	<header class="pt-4">
		<v-layout row class="mb-4">
			<v-flex xs12 md12>
				<v-layout wrap justify-start class="items-center">
					<h1 class="text--text mb-2" style="opacity: 0.8; font-size: 35px">
						Faturas
					</h1>
					<v-tabs v-model="tabSelected" centered class="marginMenu">
						<v-tab
							class="tab"
							v-for="tab in tabs"
							:key="tab.key"
							@click="$emit('tabChanged', tab.key)"
							:class="{ 'delay-text': tab.style === 'delay' }">
							{{ tab.text }}
							<v-badge
								:class="tab.style == 'delay' ? 'badge delay-badge' : 'badge'"
								color="primary">
								<span> {{ tab.value || 0 }} </span>
							</v-badge>
						</v-tab>
					</v-tabs>
					<v-flex class="q-row items-center justify-end">
						<v-text-field hide-details v-model="search"
							placeholder="Pesquisar" append-icon="search" class="sizeInput mr-3 pb-3"/>
						<v-select
							@change="emitFilterChanged"
							v-model="selectedStatus"
							:items="statuses"
							:item-text="(item) => `${item.label}`"
							item-value="value"
							return-object
							chips
							label="Status"
							multiple
							class="px-2 sizeStatus">
							<v-list-tile
								slot="prepend-item"
								ripple
								@click="toggleAll">
								<v-list-tile-action>
									<v-icon medium>done_all</v-icon>
								</v-list-tile-action>
								<v-list-tile-title>Todos</v-list-tile-title>
							</v-list-tile>
							<v-divider
								slot="prepend-item"
								class="mt-2"/>
						</v-select>
						<v-menu
							class="px-2"
							close-on-content-click>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									depressed
									class="filter-button black--text sizeButtonFilter" style="margin: 0">
									<span style="flex-grow: 1">{{ companyUnitButtonLabel }}</span>
									<v-icon small class="ml-3 mr-2">keyboard_arrow_down</v-icon>
								</v-btn>
							</template>
							<v-list class="py-5">
								<v-list-tile>
									<v-list-tile-title>Unidade</v-list-tile-title>
								</v-list-tile>
								<v-divider></v-divider>
								<v-list-tile v-for="(type, index) in companyUnitOptions" :key="index" class="options pointer" @click="companyUnitSelected = type.value">
									<v-flex>
										<span style="opacity: 1; color: black;">{{ type.label }}</span>
									</v-flex>
								</v-list-tile>
							</v-list>
						</v-menu>
						<v-menu
							class="px-2"
							close-on-content-click>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									depressed
									class="filter-button black--text sizeButtonFilter" style="margin: 0">
									<span style="flex-grow: 1">{{ typeButtonLabel }}</span>
									<v-icon small class="ml-3 mr-2">keyboard_arrow_down</v-icon>
								</v-btn>
							</template>
							<v-list class="py-5">
								<v-list-tile>
									<v-list-tile-title>Tipo</v-list-tile-title>
								</v-list-tile>
								<v-divider></v-divider>
								<v-list-tile v-for="(type, index) in types" :key="index" class="options pointer" @click="typeSelected = type.value">
									<v-flex>
										<span style="opacity: 1; color: black;">{{ type.label }}</span>
									</v-flex>
								</v-list-tile>
							</v-list>
						</v-menu>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</header>
</template>

<script>
import { unidades } from '@Config/unidades';
import {
	statusLabel as invoiceStatusLabel, statusEnum, typesEnum, getInvoiceStatusAttributes,
	getInvoiceTypeAttributes,
	typesLabel
} from '@Consts/invoices';

export default {
	name: 'HeaderInvoices',
	props: {
		invoices: {
			type: Array,
			default: () => []
		},
		tabs: {
			type: Array,
			required: false
		}
	},
	data() {
		return {
			statuses: [
				{ label: invoiceStatusLabel.PENDING, value: statusEnum.PENDING },
				{ label: invoiceStatusLabel.PAID, value: statusEnum.PAID },
				{ label: invoiceStatusLabel.REFUND, value: statusEnum.REFUND },
				{ label: invoiceStatusLabel.PARTIAL_REFUND, value: statusEnum.PARTIAL_REFUND },
				{ label: invoiceStatusLabel.WAITING_REFUND, value: statusEnum.WAITING_REFUND },
				{ label: invoiceStatusLabel.CANCELED, value: statusEnum.CANCELED },
				{ label: invoiceStatusLabel.EXPIRED, value: statusEnum.EXPIRED },
				{ label: invoiceStatusLabel.PROTESTED, value: statusEnum.PROTESTED },
				{ label: invoiceStatusLabel.CONTESTED, value: statusEnum.CONTESTED },
				{ label: invoiceStatusLabel.OVERDUE, value: statusEnum.OVERDUE }
			],
			types: [
				{ label: 'Todos', value: '' },
				...Object.keys(typesEnum).map(key => ({ value: typesEnum[key], label: typesLabel[typesEnum[key]] }))
			],
			companyUnitOptions: [
				{ label: 'Todas', value: '' },
				...unidades.map(unidade => ({ label: unidade.nome, value: unidade.key }))
			],
			search: '',
			selectedStatus: [],
			typeSelected: null,
			companyUnitSelected: null,
			tabSelected: 0
		};
	},
	methods: {
		toggleAll() {
			this.selectedStatus = [];
			this.emitFilterChanged();
		},
		emitFilterChanged() {
			const statusValue = this.selectedStatus.map(status => status.value);

			const params = {
				q: this.search || null,
				status: statusValue,
				type: this.typeSelected,
				companyUnit: this.companyUnitSelected
			};

			const tabIndexForCurrentStatus = this.tabs.findIndex(tab => tab.key === statusValue[0]);
			if (statusValue.length === 1 && tabIndexForCurrentStatus !== -1)
				this.tabSelected = tabIndexForCurrentStatus;
			else
				this.tabSelected = 0;

			return this.$emit('statusChanged', params);
		}
	},
	watch: {
		typeSelected: {
			handler() {
				this.emitFilterChanged();
			},
			deep: true
		},
		companyUnitSelected: {
			handler() {
				this.emitFilterChanged();
			},
			deep: true
		},
		search(value) {
			const statusValue = this.selectedStatus.map(status => status.value);
			const params = {
				q: value,
				status: this.tabSelected === 0 ? statusValue : this.tabs.find((t, index) => index === this.tabSelected).key,
				type: this.typeSelected,
				companyUnit: this.companyUnitSelected
			};
			this.$emit('onSearch', params);
		}
	},
	computed: {
		statusButtonLabel() {
			return this.statusSelected ? getInvoiceStatusAttributes(this.statusSelected).label : 'Status';
		},
		typeButtonLabel() {
			return this.typeSelected ? getInvoiceTypeAttributes(this.typeSelected).label : 'Tipo';
		},
		companyUnitButtonLabel() {
			return this.companyUnitSelected ? unidades.find(unit => unit.key === this.companyUnitSelected).nome : 'Unidade';
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.delay-text {
	color: $error !important;
}
.delay-badge {
	background-color: $error !important;
	color: white;
}

.divider {
	border-width: 5px;
	border-radius: 0;
	border-color: #ffd9a0 !important;
}

.tab {
	text-transform: none;
	background-color: $primary_light !important;
	padding: 10px;
}

.badge {
	margin-left: 10px;
	background-color: $primary;
	padding: 4px 10px;
	border-radius: 5px;
}

.filter-button {
	background-color: $primary-light !important;
	border-radius: 12px;
	border: 1.166px solid #3b2c1b;
}

.sizeInput {
	@media screen and (min-width: $break-point-md) {
    max-width: 100px;
  }

	@media screen and (min-width: 1300px) {
    max-width: 230px;
  }
}

.sizeStatus {
	@media screen and (min-width: $break-point-md) {
    max-width: 160px;
  }

	@media screen and (min-width: 1300px) {
    max-width: 300px;
  }
}

.sizeButtonFilter {
 @media screen and (min-width: $break-point-md) {
    min-width: 80px;
  }

	@media screen and (min-width: 1300px) {
    min-width: 200px;
  }
}

.options {
	width: 100%;

	&:hover {
		background-color: $primary-light;
	}
}

.marginMenu {
	@media screen and (min-width: $break-point-md) {
    margin-left: 16px;
  }

	@media screen and (min-width: 1300px) {
    min-width: 20px;
  }
}
</style>
