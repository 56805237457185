import $http from '@Config/axios';
import { DefaultPresenter } from './default-presenter';

const prefix = 'envelope';
const entity = 'Envelope';

function sanitize(envelopesData) {
	return {
		...envelopesData,
		envelopes: envelopesData.envelopes && envelopesData.envelopes !== '' ? envelopesData?.envelopes?.split(',') : null
	};
}

export const EnvelopePresenter = {
	...DefaultPresenter({ prefix, entity }),
	/**
	 * Adiciona envelope(s) vinculados a um ID de assinatura
	 *
	 * @typedef {Object} Params
	 * @property {String} envelopes
	 * @property {Number} subscriptionId
	 * @property {Boolean} hasStockLeftOver
	 *
	 * @param {Params} params
	 * @returns {Promise<>}
	 */
	async linkEnvelopesWithSubscription(params = {}) {
		return $http.post(`${prefix}/`, sanitize(params));
	}
};
