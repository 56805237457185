import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';
import { FormatDateFromRequest } from '@Util/functions';
import $store from '@Store';
import { DefaultPresenter } from './default-presenter';

const prefix = 'assinatura';
const entity = 'Assinatura';

export const AssinaturaPresenter = {
	...DefaultPresenter({ prefix, entity }),
	async list({
		filtro, page, rowsPerPage, sortBy, order, search, casasRepouso
	}) {
		return $http.get(`${prefix}`, {
			params: {
				page,
				rowsPerPage,
				sortBy,
				order,
				search,
				filtro,
				casa_repouso_ids: casasRepouso,
				visao_colaborador: $store.getters.visao_colaborador ? 1 : 0
			}
		});
	},
	show(assinatura_id) {
		return new Promise((resolve, reject) => {
			$http.get(`${prefix}/${assinatura_id}`)
				.then(response => {
					response.data_proxima_box = FormatDateFromRequest(response.data_proxima_box);
					resolve(response);
				})
				.catch(response => reject(response));
		});
	},
	destroy: (id, motivo_cancelamento) => new Promise((resolve, reject) => {
		$http.post(`${prefix}/${id}/delete`, { motivo_cancelamento })
			.then(response => {
				$store.dispatch('SHOW_SNACKBAR', { message: `${entity} cancelada com sucesso!` });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	}),
	cobrarCartao(params) {
		return new Promise((resolve, reject) => {
			$http.post(`${prefix}/${params.assinatura_id}/cobrarCartao`, params)
				.then(response => {
					$store.dispatch('SHOW_SNACKBAR', { message: 'Cobrança feita com sucesso!' });
					resolve(response);
				})
				.catch(response => reject(response));
		});
	},
	relacaoDeMedicamentos: (assinatura_id, nome, cpf) => $http.get(buildQueryParams('relatorios/relacaoDeMedicamentos', { assinatura_id, nome, cpf })),
	count: ({ endereco_id }) => $http.get(`${prefix}/count`, { params: { endereco_id } }),
	assinaturasParaCompra: query => $http.get(buildQueryParams(`${prefix}/assinaturasParaCompra`, query)),
	medicamentos: assinatura_id => $http.get(`${prefix}/${assinatura_id}/medicamentos`),
	historicoBoxes: assinatura_id => new Promise((resolve, reject) => {
		$http.get(`${prefix}/${assinatura_id}/boxes`)
			.then(response => {
				const boxes = response.map(box => {
					box.inicio = FormatDateFromRequest(box.inicio);
					return box;
				});
				resolve(boxes);
			})
			.catch(response => reject(response));
	}),
	historico: assinatura_id => $http.get(`${prefix}/${assinatura_id}/historico`),
	listaDeCompras: ({
		ids, historico, picking, compra
	}) => $http.get(buildQueryParams(`${prefix}/listaDeCompras`, {
		ids, historico, picking, compra
	})),
	geraEtiquetas: params => $http.post(`${prefix}/geraEtiquetas`, params),
	updateIuguId: (assinatura_id, iugu_id) => $http.put(`${prefix}/${assinatura_id}/iugu`, { assinatura_id, iugu_id }),
	listaClientesIugu: () => $http.get('listaClientesIugu'),
	buscaClienteIugu: iugu_id => $http.get(`buscaClienteIugu/${iugu_id}`),
	removerFormasDePagamento: iugu_id => $http.delete(`formasDePagamento/${iugu_id}`),
	updateNoHarm: assinatura_id => $http.put(`assinaturamedicamento/${assinatura_id}/noHarm`),
	buscaOuCriaClienteIugu: assinatura_id => $http.get(`/buscaOuCriaClienteIugu/${assinatura_id}`),
	updateObservacao: (assinatura_id, observacao) => $http.patch(`${prefix}/${assinatura_id}/atualizaObservacao`, { observacao }),
	updateModalidade: (assinatura_id, modalidade) => $http.patch(`${prefix}/${assinatura_id}/atualizaModalidade`, { modalidade })
};
