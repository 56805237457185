import { render, staticRenderFns } from "./draft-dialog-header.vue?vue&type=template&id=f92f1d8c&scoped=true"
import script from "./draft-dialog-header.vue?vue&type=script&lang=js"
export * from "./draft-dialog-header.vue?vue&type=script&lang=js"
import style0 from "./draft-dialog-header.vue?vue&type=style&index=0&id=f92f1d8c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f92f1d8c",
  null
  
)

export default component.exports