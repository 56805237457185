<template>
	<Dialog titulo="Estoque" :subtitulo="paciente.nome" @close="close"
		btn_acao="Salvar" btn_acao_extra="Ver medicamentos cadastrados" btn_acao_extra_class="primary" :carregando="loading_medicamentos" :loading="salvando"
		:btn_acao_disable="!medicamentos.length" @acao="salvar" @acao_extra="abrirMedicamentos" >
		<v-container grid-list-md class="pa-0">
			<v-layout wrap>
				<template v-if="medicamentos.length > 0">
					<v-flex xs6>
						<div  v-if="pickigWithLabelFeatureFlag" class="label-input">
							<h4 v-if="!withoutLabel" class="mb-2" >
								Utilize um leitor de código de barras ou insira os dados da <b>etiqueta</b> manualmente
							</h4>
							<inputLabel
								v-if="!withoutLabel"
								:label="label"
								:labelIsRequired="isPickingWithTagRequired"
								:withoutLabel="withoutLabel"
								:focusEnabled="initFocusLabel"
								ref="labelRef"
								@filled="val => fillLabel(val)" />
							<div v-if="!isPickingWithTagRequired">
								<v-checkbox
									primary
									hide-details
									v-model="withoutLabel"
									label="Este medicamento não tem etiqueta."/>
							</div>
							<hr v-if="!withoutLabel" class="my-2">
						</div>
						<Medicamento class="mb-2"/>
						<InputCodigoBarras
							:label="label"
							:withoutLabel="withoutLabel"
							:labelIsRequired="isPickingWithTagRequired"
							ref="eanRef"
							:focusEnabled="initFocusEan"
							@loading="val => loading_image = val"
							@image="val => image = val"
							@mostrarMsg="val => mostrarMsg = val"
							@medicinePicked="medicinePicked"/>

						<Images :image="image" :mostrarMsg="mostrarMsg" :loading="loading_image"/>
					</v-flex>
					<v-flex xs6>
						<ListaMedicamentos />
					</v-flex>
				</template>
				<v-flex v-else xs12 class="estoque-suficiente ma-4">
					<h2>O estoque do paciente é suficiente</h2>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog persistent v-model="confirmacao_ean.dialog" scrollable transition="fade" max-width="400px">
			<transition>
				<Dialog v-if="confirmacao_ean.dialog" titulo="Atenção!" subtitulo="Um ou mais medicamentos não possuem EAN. Deseja continuar?"
					btn_acao="Continuar" btn_acao_class="error" btn_cancelar="Cancelar"
					@acao="confirmar('confirmacao_ean')" @close="reset" />
			</transition>
		</v-dialog>

	</Dialog>
</template>

<script>
import { MovimentacaoPresenter } from '@Presenter/movimentacao-presenter';
import unleash from '@Util/unleash';
import { featureFlags } from '@Consts/feature-flags';
import InputCodigoBarras from './input-codigo-barras';
import inputLabel from './input-label';
import Medicamento from './medicamento';
import ListaMedicamentos from './lista-medicamentos';
import Images from './images.vue';

export default {
	name: 'DialogBipe',
	components: {
		InputCodigoBarras, Medicamento, ListaMedicamentos, Images, inputLabel
	},
	props: {
		box_id: {
			type: Number,
			required: true
		},
		subscriptionId: {
			type: Number,
			required: true
		},
		paciente: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		confirmacao_ean: {
			dialog: false,
			confirmou: false
		},
		salvando: false,
		image: null,
		mostrarMsg: false,
		loading_image: false,
		label: null,
		withoutLabel: false,
		initFocusEan: false,
		initFocusLabel: false,
		pickigWithLabelFeatureFlag: !!unleash.isEnabled('FARMEBOX_PICKING_WITH_TAG')
	}),
	computed: {
		loading_medicamentos() {
			return this.$store.state.bipe.loading_medicamentos;
		},
		medicamentos() {
			return Object.values(this.$store.state.bipe.medicamentos);
		},
		isPickingWithTagRequired() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.picking_with_tag_required);
		}
	},
	created() {
		this.$store.dispatch('bipe/FETCH_MEDICAMENTOS', this.box_id);
		unleash.on(
			'update',
			() => {
				this.updateFeatureFlag(unleash.isEnabled('FARMEBOX_PICKING_WITH_TAG'));
			}
		);
		this.initFocus();
	},
	beforeDestroy() {
		this.$store.dispatch('bipe/CLEAR_STATE');
	},
	watch: {
		withoutLabel(value) {
			if (value)
				this.inputFocusEan();
			else
				this.inputFocusLabel();
		},
		pickigWithLabelFeatureFlag(value) {
			this.withoutLabel = !value;
		}
	},
	methods: {
		initFocus() {
			if (this.pickigWithLabelFeatureFlag)
				this.inputFocusLabel();
			else {
				this.withoutLabel = true;
				this.inputFocusEan();
			}
		},
		updateFeatureFlag(value) {
			this.pickigWithLabelFeatureFlag = value;
		},
		medicinePicked() {
			this.label = null;
			this.inputFocusLabel();
		},
		inputFocusLabel() {
			if (this.pickigWithLabelFeatureFlag)
				this.withoutLabel = false;

			this.initFocusLabel = true;
			this.initFocusEan = false;
		},
		inputFocusEan() {
			this.initFocusEan = true;
			this.initFocusLabel = false;
		},
		reset() {
			this.confirmacao_ean.dialog = false;
			this.confirmacao_ean.confirmou = false;
			this.inputFocusLabel();
		},
		confirmar(nome_do_objeto) {
			this[nome_do_objeto].dialog = false;
			this[nome_do_objeto].confirmou = true;
			this.salvar();
		},
		fillLabel(value) {
			this.inputFocusEan();
			this.label = value;
		},
		salvar() {
			const items = [];
			let insuficiente = false;
			let maisQueONecessario = false;
			let medicamentoSemEan = false;
			this.medicamentos.forEach(medicamento => {
				insuficiente = (medicamento.quantidade_inserida < medicamento.caixas) || insuficiente;
				maisQueONecessario = (medicamento.quantidade_inserida > medicamento.caixas) || maisQueONecessario;
				medicamentoSemEan = (!medicamento.ean) || medicamentoSemEan;
				items.push({
					etiquetas: medicamento.etiquetas,
					quantidade: medicamento.quantidade_inserida * medicamento.qtd_embalagem,
					box_id: this.box_id,
					paciente_id: this.paciente.id,
					medicamento_id: medicamento.id
				});
			});
			if (insuficiente) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Medicamento(s) com quantidade menor que o necessário.' });
				this.inputFocusLabel();
				return;
			}
			if (maisQueONecessario) {
				this.$store.dispatch('SHOW_SNACKBAR',
					{
						color: 'error',
						message: `
							Medicamento(s) com quantidade maior que o necessário,
							por favor, insira através do estoque do paciente a quantidade que ultrapassar o necessário
						`
					});
				this.inputFocusLabel();
				return;
			}
			if (medicamentoSemEan && !this.confirmacao_ean.confirmou) {
				this.confirmacao_ean.dialog = true;
				return;
			}
			this.salvando = true;

			MovimentacaoPresenter.storeEntrada({ items, subscriptionId: this.subscriptionId })
				.then(() => {
					this.close();
				})
				.finally(() => {
					this.reset();
					this.salvando = false;
				});
		},
		abrirMedicamentos() {
			const routeData = this.$router.resolve({ name: 'Medicamentos' });
			window.open(routeData.href, '_blank');
		},
		close() {
			this.$emit('update:close', false);
		}
	}
};
</script>

<style lang="scss" scoped>
.estoque-suficiente {
	display: flex;
	align-items: center;
	justify-content: center;
}

.label-input{
	h4 {
		margin-bottom: 16px;
		padding: 8px;
		border: 1px lightgrey solid;
		border-radius: 10px;
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		margin-bottom: 0;
		text-transform: uppercase;
		font-size: 14px;
		line-height: 12px;
	}
}
</style>
