const DISCOUNT_PERCENTAGE_TYPE_VALUE = 'PERCENTUAL';
const DISCOUNT_PERCENTAGE_TYPE_TEXT = 'Porcentagem (%)';
export const DISCOUNT_PERCENTAGE_TYPE = Object.freeze({
	value: DISCOUNT_PERCENTAGE_TYPE_VALUE,
	text: DISCOUNT_PERCENTAGE_TYPE_TEXT,
	icon: '%'
});

const DISCOUNT_AMOUNT_TYPE_VALUE = 'VALOR_REAL';
const DISCOUNT_AMOUNT_TYPE_TEXT = 'Valor real (R$)';
export const DISCOUNT_AMOUNT_TYPE = Object.freeze({
	value: DISCOUNT_AMOUNT_TYPE_VALUE,
	text: DISCOUNT_AMOUNT_TYPE_TEXT,
	icon: 'R$'
});

export const DISCOUNT_TYPES = Object.freeze([
	DISCOUNT_PERCENTAGE_TYPE,
	DISCOUNT_AMOUNT_TYPE
]);
