<template>
	<Dialog
		:titulo="is_new ? 'Cadastrar farmembro' : 'Editar farmembro'"
		:btn_acao="nomeAcao"
		:btn_acao_disable="!formIsFilled"
		:btn_excluir="regraExcluir"
		@acao="lidarAcao"
		@excluir="modal_excluir = true"
		@close="fechaModal"
		:loading="salvando">
		<div v-if="loading" class="loading">
			<v-progress-circular indeterminate color="primary"/>
		</div>
		<v-tabs
			v-else
			v-model="etapa_atual"
			centered
			color="purple-title"
			slider-color="green"
			dark
			icons-and-text
			class="tabs">
			<v-tab href="#tab-dados">
				Dados pessoais
				<v-icon>assignment_ind</v-icon>
			</v-tab>

			<v-tab href="#tab-clinica" :class="{ 'tabs__item--disable': is_new }">
				Clínica
				<v-icon>medical_services</v-icon>
			</v-tab>

			<v-tab href="#tab-responsavel" :class="{ 'tabs__item--disable': is_new }">
				Responsáveis
				<v-icon>account_circle</v-icon>
			</v-tab>
			<v-tab-item value="tab-dados">
				<v-container fluid grid-list-lg>
					<Form :campos="campos" :item="item">
						<v-layout wrap class="flex items-start">
							<v-flex xs12 md4>
								<div class="box" @click="dialog_tags = true">
									<label
										class="ml-2 v-label"
										:class="{ 'with-items': (item.tags || []).length > 0 }">
										Tags
									</label>
									<v-chip
										v-for="tag in item.tags"
										:key="tag.id"
										disabled
										class="purple white--text">
										{{ tag.nome }}
									</v-chip>
								</div>
							</v-flex>

							<v-flex xs12 md4 v-if="is_new">
								<v-autocomplete
									box
									v-model="casa_repouso_id"
									color="green"
									:loading="loading"
									label="Casa de repouso *"
									item-value="id"
									item-text="nome"
									:items="camposClinica.casa_repouso_id.items"
									:return-object="false"
									clearable
									v-validate="{ required: true }"
									data-vv-name="Casa de repouso"
									:error-messages="errors.collect('Casa de repouso')" />
							</v-flex>
						</v-layout>
					</Form>
				</v-container>
			</v-tab-item>

			<v-tab-item value="tab-clinica">
				<v-container fluid grid-list-lg>
					<Form :campos="camposClinica" :item="item">
						<v-flex xs12 md6>
							<div class="box" @click="dialog_alergias = true">
								<label
									class="ml-2 v-label"
									:class="{ 'with-items': (item.alergias || []).length > 0 }">
									Alergias
								</label>
								<v-chip
									v-for="alergia in item.alergias"
									:key="alergia.id"
									disabled
									class="purple white--text">
									{{ alergia.descricao }}
								</v-chip>
							</div>
						</v-flex>
					</Form>
				</v-container>
			</v-tab-item>

			<v-tab-item value="tab-responsavel">
				<v-container>
					<v-flex xs12>
						<p>
							<strong>IMPORTANTE</strong>: Caso não adicione uma pessoa
							responsável por Faturamento, Clínico e/ou Financeiro, o próprio farmembro será
							atribuido como responsável.
						</p>
					</v-flex>
					<Responsavel
						v-if="etapa_atual === 'tab-responsavel'"
						:paciente-id="item.id"
						:patientResponsibleFromQuotation="patientResponsibleFromQuotation"
						:sendUpdatePatientResponsibles="sendUpdatePatientResponsibles"
						:restHomeId="casa_repouso_id"
						:checkFinancialResponsible="true"/>
					<v-tooltip top color="purple darken-4" :max-width="450">
						<template v-slot:activator="{ on }">
							<strong class="purple--text text--darken-3" v-on="on">
								<v-icon color="purple darken-3" style="margin-bottom: -3px">help_outline</v-icon>
								O que são os responsáveis?
							</strong>
						</template>
						<div class="pa-4">
							<p class="text-lg-left body-1">
								<strong>Responsável Faturamento</strong>: para quem a nota
								fiscal será faturada. É permitido apenas uma pessoa responsável
								por farmembro.
							</p>
							<p class="text-lg-left body-1">
								<strong>Responsável Clínico</strong>: é a pessoa que mais
								acompanha a saúde do farmembro (ex.: familiar, cuidador ou
								enfermeiro) é permitido apenas um responsável clínico por
								farmembro.
							</p>
							<p class="text-lg-left body-1">
								<strong>Responsável Financeiro:</strong> é quem faz o pagamento
								da assinatura e para quem o boleto será direcionado. É permitido
								apenas um por farmembro.
							</p>
							<p class="text-lg-left body-1">
								* O boleto aparecerá conforme dados cadastrados no IUGU.
							</p>
						</div>
					</v-tooltip>
				</v-container>
			</v-tab-item>
		</v-tabs>

		<v-dialog
			persistent
			scrollable
			v-model="modal_excluir"
			transition="fade"
			max-width="340px">
			<transition>
				<Dialog
					v-if="modal_excluir"
					:titulo="messagem_dialog_excluir.titulo"
					:subtitulo="messagem_dialog_excluir.subtitulo"
					:btn_acao="messagem_dialog_excluir.btn_acao"
					btn_acao_class="error"
					btn_cancelar="Cancelar"
					@acao="excluir()"
					:loading="salvando"
					@close="modal_excluir = false"/>
			</transition>
		</v-dialog>

		<v-dialog
			v-model="dialog_alergias"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogAlergias
					v-if="dialog_alergias"
					:alergias="item.alergias"
					@novas-alergias="onNovasAlergias"
					@close="dialog_alergias = false"/>
			</transition>
		</v-dialog>

		<v-dialog
			v-model="dialog_tags"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogTags
					v-if="dialog_tags"
					:tags="item.tags"
					@novas-tags="(tags) => (item.tags = tags)"
					@close="dialog_tags = false"/>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import DialogTags from '@Componentes/dialog-tags';
import DialogAlergias from '@Componentes/dialog-alergias';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import { CRUDMixin } from '@Mixins/crud';
import Responsavel from '@Componentes/pacientes/paciente/info-responsavel';

export default {
	name: 'DialogPaciente',
	components: { Responsavel, DialogAlergias, DialogTags },
	props: {
		paciente_orcamento: {
			type: Object
		},
		patientResponsibleFromQuotation: {
			type: Object,
			required: false,
			default: null
		},
		sendUpdatePatientResponsibles: {
			type: Function,
			required: false
		}
	},
	mixins: [CRUDMixin],
	data: () => ({
		casa_repouso_id: null,
		etapa_atual: 'tab-dados',
		item: {
			responsaveis: [],
			casa_repouso: {},
			alergias: []
		},
		campos_externos: {},
		camposClinica: {},
		dialog_tags: false,
		dialog_alergias: false,
		type: 'paciente',
		fields_type: 'paciente-dados',
		loading: true
	}),
	async created() {
		if (this.paciente_orcamento)
			this.casa_repouso_id = this.paciente_orcamento.casaRepousoId;
		try {
			const Fields = await import('@Fields/paciente-clinica-fields');
			this.camposClinica = this.$lodash.cloneDeep(Fields.default);

			this.camposClinica.casa_repouso_id.items = await CasaRepousoPresenter.index({
				campos: ['id', 'nome']
			});

			if (this.paciente_orcamento)
				this.item = { ...this.paciente_orcamento };
			else {
				this.item = { ...this.item_pai };
				this.responsaveis = this.item.responsaveis ?? null;
			}

			if (this.item.sonda)
				this.item.triturar_medicamentos = 'Sim';

			if (this.paciente_orcamento?.casa_repouso_id)
				this.casa_repouso_id = this.paciente_orcamento.casa_repouso_id;
		} finally {
			this.loading = false;
		}

		if (!this.is_new) {
			this.campos.cpf = {
				...this.campos.cpf,
				disabled: !this.$store.getters.eh_tech_team
			};
		}
	},
	watch: {
		etapa_atual(etapa) {
			this.campos_externos = {};

			if (etapa === 'tab-clinica')
				this.campos_externos = this.camposClinica;
		},
		item: {
			handler(item) {
				if (item.triturar_medicamentos === 'Sim') {
					delete this.camposClinica.sonda.disabled;
					this.camposClinica.sonda = {
						...this.camposClinica.sonda,
						validation: { required: true }
					};
				} else if (
					!item.triturar_medicamentos
						|| item.triturar_medicamentos === 'Não'
				) {
					delete this.camposClinica.sonda.validation;
					this.item.sonda = '';
					this.camposClinica.sonda = {
						...this.camposClinica.sonda,
						disabled: true
					};
				}
			},
			deep: true
		}
	},
	computed: {
		is_new() {
			return !(this.item || {}).id;
		},
		regraExcluir() {
			if (this.etapa_atual === 'tab-responsavel')
				return '';

			return this.messagem_dialog_excluir.btn_excluir;
		},
		nomeAcao() {
			return this.etapa_atual === 'tab-responsavel' ? 'Pronto' : 'Salvar';
		},
		formIsFilled() {
			if (this.is_new)
				return this.habilitarBtnAcao && this.casa_repouso_id;

			return this.habilitarBtnAcao;
		}
	},
	methods: {
		setItem() {
		},
		async salvar(data) {
			this.salvando = true;
			try {
				const $http = this.is_new ? this.requestStore : this.requestUpdate;
				const _data = this.is_new
					? {
						...data,
						casa_repouso_id: this.casa_repouso_id
					} : data;
				const response = await $http(_data);
				this.item = response;
				this.$emit('inserir', response);

				if (this.etapa_atual === 'tab-dados')
					this.etapa_atual = 'tab-clinica';
				else
					this.etapa_atual = 'tab-responsavel';
			} finally {
				this.salvando = false;
			}
		},
		lidarAcao() {
			if (this.etapa_atual === 'tab-responsavel')
				this.fechaModal();
			else
				this.submeter();
		},
		onNovasAlergias(alergias) {
			this.item.alergias = alergias;
		},
		createData() {
			return this.$lodash.cloneDeep(this.item);
		}
	}
};
</script>

<style scoped>
.tabs {
	margin: -16px -16px -16px;
}

.tabs__item--disable {
	pointer-events: none;
}
</style>
