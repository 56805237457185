<template>
	<v-dialog
		persistent
		scrollable
		v-model="editMedicineDialog"
		transition="fade"
		max-width="600px">

		<template v-slot:activator="{ on }">
			<v-btn fab small color="#FFD9A0CC" v-on="on" @click.stop="editMedicineDialog = true">
				<v-icon color="secondary">edit</v-icon>
			</v-btn>
		</template>

		<EditMedicineDialog
			@fechar-dialog="editMedicineDialog = false"
			@medicamento-salvo="editProduct"
			:medicamento_selecionado="item"
			:medicamento="item" />
	</v-dialog>
</template>

<script>
import EditMedicineDialog from '@Componentes/medicamento/dialog-medicamento/dialog-medicamento.vue';

export default {
	components: {
		EditMedicineDialog
	},
	props: {
		item: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			editMedicineDialog: false
		};
	},
	methods: {
		editProduct(data) {
			this.$emit('editItem', data);
			this.editMedicineDialog = false;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
