<template>
	<section>
		<h3 class="my-3">Dados gerais</h3>
		<v-layout wrap>
			<!-- <v-flex xs12>
				<v-autocomplete
					v-model="form.umbrellaItemId"
					background-color="white"
					:items="umbrellaItems"
					label="Item guarda-chuva"
					box
					clearable
					v-validate="{ required: false }"
					data-vv-name="Item guarda-chuva"
					:error-messages="errors.collect('Item guarda-chuva')"
					item-value="id"
					item-text="description"/>
			</v-flex> -->
			<v-flex md6 xs12>
				<v-text-field
					v-model="form.name"
					background-color="white"
					box
					label="Nome"
					data-vv-name="Nome"
					v-validate="{ required: true }"
					:error-messages="errors.collect('Nome')"
					@input="(val) => (form.name = form.name.toUpperCase())"
					:disabled="shouldDisableFields && (!medicineForm.type || medicineForm.type === medicineTypes.GENERICO)"/>
			</v-flex>
			<v-flex md6 xs12>

				<v-autocomplete
					v-model="form.group"
					background-color="white"
					:items="availableGroups"
					label="Grupo"
					box
					clearable
					:filter="filterGroups"
					v-validate="{ required: true }"
					data-vv-name="Grupo do produto"
					:error-messages="errors.collect('Grupo do produto')"
					return-object
					item-text="name">
					<template slot="item" slot-scope="data">
						<v-list-tile-content>
							<v-list-tile-title v-html="`${data.item.display}`" />
						</v-list-tile-content>
					</template>
				</v-autocomplete>
			</v-flex>
			<v-flex xs12>
				<v-text-field
					v-model="form.presentation"
					background-color="white"
					box
					label="Apresentação"
					data-vv-name="Apresentação"
					v-validate="{ required: true }"
					:error-messages="errors.collect('Apresentação')"
					:disabled="shouldDisableFields"/>
			</v-flex>
			<v-flex xs12>
				<v-textarea
					v-model="form.description"
					background-color="white"
					label="Descrição"
					box
					v-validate="{ required: false }"
					data-vv-name="Descrição"
					:error-messages="errors.collect('Descrição')"
					:disabled="shouldDisableFields"/>
			</v-flex>
			<v-flex xs12>
				<v-autocomplete
					v-model="form.storageCondition"
					background-color="white"
					:items="storageConditions"
					box
					label="Condição de armazenamento"
					clearable
					data-vv-name="Condição de armazenamento"
					v-validate="{ required: false }"
					:error-messages="errors.collect('Condição de armazenamento')"/>
			</v-flex>
			<!-- <v-flex xs12>
				<v-layout wrap>
					<v-flex xs12>
						<v-autocomplete
							v-model="form.associatedConditionId"
							background-color="white"
							:items="associatedConditions"
							box
							label="Condição associada"
							clearable
							item-value="id"
							item-text="condition"
							v-validate="{ required: false }"
							data-vv-name="associatedConditionId"
							append-outer-icon="add"
							@click:append-outer="openAssociatedConditionCreationDialog = true"
							:error-messages="errors.collect('associatedConditionId')"/>
					</v-flex>
				</v-layout>
			</v-flex> -->
			<!-- <v-flex md6 xs12>
				<v-text-field
					v-model="form.ncm"
					background-color="white"
					box
					label="NCM"
					data-vv-name="NCM"
					v-validate="{ required: false }"
					v-mask="['####.##.##']"
					:error-messages="errors.collect('NCM')"/>
			</v-flex> -->
			<v-flex md12 xs12>
				<v-combobox
					v-model="form.gtins"
					type="number"
					background-color="white"
					box
					label="GTIN"
					multiple
					@input="check"
					small-chips
					data-vv-name="GTIN"
					v-validate="{ required: true }"
					:readonly="!canAddGtin"
					:error-messages="errors.collect('GTIN')">
					<template v-slot:selection="data">
						<v-chip
							:close="!originalEans.includes(data.item)"
							class="pointer"
							@click="copyGtin(data.item)"
							@input="deleteEAN(data.item)">
							<strong>{{ data.item }}</strong>
						</v-chip>
					</template>
				</v-combobox>
			</v-flex>
		</v-layout>

		<v-dialog
			v-model="openAssociatedConditionCreationDialog"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogCreateAssociateCondition
					v-if="openAssociatedConditionCreationDialog"
					@close="closeAssociateConditionDialog()"/>
			</transition>
		</v-dialog>
	</section>
</template>

<script>
import { copyToClipboard } from '@Util/functions';
import { itemType } from '@Consts/product/item-type';
import { medicineTypes } from '@Consts/medicine-types';
import DialogCreateAssociateCondition from '../../../dialog-new-associate-condition.vue';

export default {
	name: 'NewProductStepItemGeneralData',
	inject: ['$validator'],
	components: {
		DialogCreateAssociateCondition
	},
	props: {
		form: {
			required: true,
			type: Object
		},
		associatedConditions: {
			required: true,
			type: Array
		},
		storageConditions: {
			required: true,
			type: Array
		},
		productGroups: {
			required: true,
			type: Array
		},
		umbrellaItems: {
			required: true,
			type: Array
		},
		medicineForm: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			originalEans: [],
			openAssociatedConditionCreationDialog: false,
			medicineTypes,
			availableGroups: []
		};
	},
	created() {
		this.originalEans = this.form.gtins;
		this.setAvailableGroups();
	},
	computed: {
		canAddGtin() {
			if (this.form.gtins.length === 0)
				return true;

			const diffCount = this.originalEans.length - this.form.gtins.length;

			return diffCount === 0;
		},
		shouldDisableFields() {
			return this.form.type === itemType.medicine;
		}
	},
	methods: {
		copyGtin(gtin) {
			copyToClipboard(gtin, 'GTIN');
		},
		check(el) {
			if (el.length === 0) {
				if (this.originalEans.length > 0)
					this.form.gtins = this.originalEans;
			}

			if (this.originalEans.length > el.length) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Não é permitido remover EANS',
					color: 'error'
				});

				this.form.gtins = [...this.originalEans];
			}
		},
		filterGroups(item, queryText) {
			const searchText = queryText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			const itemName = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			return (
				itemName.indexOf(searchText) > -1
			);
		},
		closeAssociateConditionDialog() {
			this.$emit('reloadAssociatedConditions');
			this.openAssociatedConditionCreationDialog = false;
		},
		deleteEAN(item) {
			this.form.gtins.splice(this.form.gtins.indexOf(item), 1);
			this.form.gtins = [...this.form.gtins];
		},
		setAvailableGroups() {
			if (this.form.type === itemType.medicine)
				this.availableGroups = this.productGroups.filter(pg => ['medicamentos', 'especiais'].includes(pg.name.toLowerCase())).map(pg => ({ ...pg, display: pg.name }));
			else
				this.availableGroups = this.getGroupsByTree(this.productGroups);
		},
		getGroupsByTree(productGroups) {
			const groups = [];
			productGroups.forEach(g => {
				groups.push({
					...g,
					display: `<strong>${g.name}</strong>`
				});
				groups.push(...this.groupTree(g));
			});
			return groups;
		},
		groupTree(group) {
			if (!group.childrenGroups || group.childrenGroups?.length === 0) {
				return [{
					...group,
					display: `<strong>${group.name}</strong>`
				}];
			}

			const groups = [];
			groups.push({
				...group,
				display: `<strong>${group.name}</strong>`
			});

			group.childrenGroups?.forEach(g => {
				this.groupTree(g).forEach(g2 => {
					groups.push({
						...g2,
						display: `${group.name} -> ${g2.display}`
					});
				});
			});
			return groups;
		}
	},
	watch: {
		'form.type': async function () {
			this.setAvailableGroups();
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
