<template>
	<div class="dialog-footer">
		<v-dialog
			v-model="dialogReset"
			persistent
			max-width="350">
			<template v-slot:activator="{ on }">
				<v-btn
					flat
					color="error"
					v-on="on"
					@click="dialogReset = true">Reiniciar rascunho</v-btn>
			</template>

			<section class="farme-dialog">
				<header class="farme-dialog-header">
					Reiniciar rascunho
				</header>

				<main class="farme-dialog-body">
					Tem certeza que deseja <strong>REINICIAR</strong> este rascunho? Esta ação irá apagar todos os rascunhos e retornará idêntico à assinatura.
				</main>

				<footer class="farme-dialog-footer">
					<v-btn color="secondary" flat @click="closeResetDialog()">Cancelar</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="reset()">Sim, reiniciar!</v-btn>
				</footer>
			</section>
		</v-dialog>

		<v-dialog
			v-model="dialogFinish"
			persistent
			max-width="290">
			<template v-slot:activator="{ on }">
				<v-btn
					v-on="on"
					color="success"
					class="px-5"
					@click="dialogFinish = true">
					<span class="font-weight-bold">
						Finalizar
					</span>
				</v-btn>
			</template>

			<section class="farme-dialog">
				<header class="farme-dialog-header">
					Finalizar rascunho
				</header>

				<main class="farme-dialog-body">
					Você está <strong>FINALIZANDO</strong> este rascunho, tem certeza disso?
				</main>

				<footer class="farme-dialog-footer">
					<v-spacer></v-spacer>
					<v-btn color="secondary" flat @click="closeFinishDialog()">Cancelar</v-btn>
					<v-btn color="primary" @click="finish()">Sim, finalizar!</v-btn>
				</footer>
			</section>
		</v-dialog>

		<v-dialog
			v-if="canApprove"
			persistent
			scrollable
			v-model="approveDraftDialog"
			width="390"
			transition="fade">

			<template v-slot:activator="{ on }">
				<v-btn
					color="success"
					class="px-4"
					v-on="on"
					@click.stop="approveDraftDialog = true">
					Aprovar rascunho
					<v-icon small class="ml-2">check</v-icon>
				</v-btn>
			</template>

			<section class="farme-dialog">
				<header class="farme-dialog-header">
					Aprovar alterações?
				</header>

				<main class="farme-dialog-body">
					Confirmo que validei o rascunho feito pelo atendimento
				</main>

				<footer class="farme-dialog-footer">
					<v-spacer></v-spacer>
					<v-btn color="secondary" flat @click="approveDraftDialog = false">Cancelar</v-btn>
					<v-btn color="primary" @click="approve">
						Aprovar
						<v-icon small class="ml-2">check</v-icon>
					</v-btn>
				</footer>
			</section>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'DraftDialogFooter',
	props: {
		canApprove: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dialogApprove: false,
			dialogFinish: false,
			dialogReset: false
		};
	},
	methods: {
		closeApproveDialog() {
			this.dialogApprove = false;
		},
		closeFinishDialog() {
			this.dialogFinish = false;
		},
		closeResetDialog() {
			this.dialogReset = false;
		},
		reset() {
			this.$emit('reset');
			this.closeResetDialog();
		},
		finish() {
			this.$emit('finish');
			this.closeFinishDialog();
		},
		approve() {
			this.closeApproveDialog();
			this.$emit('approve');
		}
	}
};
</script>

<style lang="scss" scoped>
.dialog-footer {
	display: flex;
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;
	justify-content: end;
}
</style>
