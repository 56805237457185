<template>
	<div class="dialog-header">
		<div class="dialog-header-content">
			<div class="dialog-title">
				<h1>Rascunho</h1>
			</div>

			<v-btn
				outline
				flat
				@click="$emit('close')"
				style="color: #342b1d;border-radius: 5px">
				Fechar <v-icon small right>close</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
import logo from '@Assets/images/farme-logo-animada.gif';

export default {
	name: 'DraftDialogHeader',
	data() {
		return {
			logo
		};
	}
};
</script>

<style lang="scss" scoped>
.dialog-header {
	padding-top: 95px;
	height: 215px;
	background-color: rgba(255, 200, 117, 0.5);
}

.dialog-header-content {
	display: flex;
	width: 100%;
	max-width: 1278px;
	margin: 0 auto;
	min-height: 100%;
	height: 100%;
	flex-direction: row;
	justify-content: space-between;
}

.dialog-title {
	h1 {
		font-size: 28px;
	}
}

</style>
