<template>
	<transition>
		<v-card
			style="
				border-radius: 0 !important;
				background-color: rgba(255, 200, 117, 0.5) !important;
			">
			<v-toolbar dark flat class="purple-title toolbarFlex" height="180px">
				<div class="container">
					<v-layout align-center justify-center>
						<v-layout column>
							<v-flex>
								<v-toolbar-title style="color: #342b1d" class="titulo display-1">Editar medicamento  <br> ou produto</v-toolbar-title>
							</v-flex>
						</v-layout>
						<v-btn style="color: #342b1d; border-radius: 5px" outline flat @click="$emit('close')">
							Fechar <v-icon small right>close</v-icon>
						</v-btn>
					</v-layout>

					<Tabs :tabSelected="tabSelected" @goto="step => goto(step)" />
				</div>
			</v-toolbar>

			<v-card-text class="cardBody">
				<Loading v-if="loading" />
				<div v-else class="container">
					<StepProduct
						v-if="tabSelected === tabs.product"
						:ref="tabs.product"
						:form="formData"
						:defaultMedicineForm="defaultMedicineForm"
						@addIngredient="addIngredient"
						@removeIngredient="(index) => removeIngredient(index)" />

					<StepPrice
						v-if="tabSelected === tabs.pricing"
						:ref="tabs.pricing"
						:prices="formData.prices"
						:availabilities="formData.availabilities"/>
					<FarmeDivider />

					<v-layout align-center justify-space-between>
						<v-btn
							flat
							color="red"
							@click="cancelCreateMedicineDialog = true">Cancelar</v-btn>

						<section>
							<v-btn class="light" v-if="tabSelected !== tabs.product" @click="back()">Voltar</v-btn>
							<v-btn
								color="primary"
								@click="next()"
								:disabled="submiting"
								:loading="submiting || loading">
								{{tabSelected === tabs.pricing ? "Salvar" : "Próximo"}}
							</v-btn>
						</section>
					</v-layout>
				</div>
			</v-card-text>

			<v-dialog
				persistent
				v-model="cancelCreateMedicineDialog"
				scrollable
				transition="fade"
				max-width="460px">
				<transition>
					<Dialog
						v-if="cancelCreateMedicineDialog"
						titulo="Cancelar cadastro medicamento/produto"
						btn_acao="Cancelar cadastro"
						btn_acao_class="error"
						btn_cancelar="Fechar"
						@close="cancelCreateMedicineDialog = false"
						@acao="$emit('close')"/>
				</transition>
			</v-dialog>
		</v-card>
	</transition>
</template>

<script>
import { ProductPresenter } from '@Presenter/product-presenter';
import { getAllCompanyUnits } from '@Consts/unidades';
import { LayerPresenter } from '@Presenter/pricing/layer-presenter';
import { stepsKeys } from '@Consts/product/product-form-steps';
import FarmeDivider from '@Componentes/farme-divider.vue';
import { availabilityKeys } from '@Consts/availabities';
import { itemType } from '@Consts/product/item-type';
import { AvailabilityPresenter } from '@Presenter/availability-presenter';
import { PricePresenter } from '@Presenter/pricing/price-presenter';
import { getPropertyValue } from '@Util/ProductService';
import Tabs from '../form/tabs.vue';
import StepProduct from '../form/steps/product/index.vue';
import StepPrice from '../form/steps/price/index.vue';
import Loading from '../form/loading.vue';

export default {
	name: 'EditProductDialog',
	$_veeValidate: { validator: 'new' },
	components: {
		Tabs,
		StepProduct,
		StepPrice,
		Loading,
		FarmeDivider
	},
	props: {
		productId: {
			required: true
		}
	},
	async created() {
		if (!this.productId)
			throw new Error('Product Id é obrigatório');

		await this.getPrices();
		await this.getProduct();

		this.loading = false;
	},
	data: () => ({
		loading: true,
		submiting: false,
		tabSelected: stepsKeys.product,
		product: {},
		defaultMedicineForm: {
			compositions: [
				{
					concentration: null,
					activeIngredient: null,
					unitOfMeasurementSymbol: null
				}
			],
			stripe: null,
			type: null,
			pbmId: null,
			routeAdministrationIds: [],
			referenceDrug: null,
			prescriptionRule: null,
			dosageFormId: null,
			atcId: null,
			atcName: '',
			anvisaId: null,
			medicineType: null,
			properties: {
				aggregateItem: null,
				isLiquid: false,
				isDivisible: true,
				isOutOfBox: false
			}
		},
		formData: {
			product: {
				type: null,
				umbrellaItemId: null,
				associatedConditionId: null,
				group: null,
				name: null,
				brand: null,
				category: null,
				presentation: null,
				description: null,
				storageCondition: null,
				ncm: null,
				gtin: null,
				gtins: [],
				height: null,
				width: null,
				length: null,
				quantity: null,
				quantityForSale: null,
				unitOfMeasurementSymbol: null,
				unitOfMeasurementForSaleSymbol: null,
				grossWeight: null,
				netWeight: null,
				manufacturer: null
			},
			medicine: {
				compositions: [
					{
						concentration: null,
						activeIngredient: null,
						unitOfMeasurementSymbol: null
					}
				],
				stripe: null,
				type: null,
				pbmId: null,
				routeAdministrationIds: [],
				prescriptionRule: null,
				dosageFormId: null,
				referenceDrug: null,
				atcId: null,
				anvisaId: null,
				properties: {
					aggregateItem: null,
					isLiquid: false,
					isDivisible: false,
					isOutOfBox: false
				},
				pmc: {
					pmcWithoutTax: '',
					pmc0: '',
					pmc12: '',
					pmc17: '',
					pmc17Alc: '',
					pmc17_5: '',
					pmc17_5Alc: '',
					pmc18: '',
					pmc18Alc: '',
					pmc19: '',
					pmc19_5: '',
					pmc19_5Alc: '',
					pmc20: '',
					pmc20_5: '',
					pmc21: '',
					pmc21Alc: '',
					pmc22: '',
					pmc22Alc: ''
				},
				pf: {
					pfWithoutTax: '',
					pf0: '',
					pf12: '',
					pf17: '',
					pf17Alc: '',
					pf17_5: '',
					pf17_5Alc: '',
					pf18: '',
					pf18Alc: '',
					pf19: '',
					pf19_5: '',
					pf19_5Alc: '',
					pf20: '',
					pf20_5: '',
					pf21: '',
					pf21Alc: '',
					pf22: '',
					pf22Alc: ''
				},
				pnu: null
			},
			prices: [],
			availabilities: []
		},
		cancelCreateMedicineDialog: false
	}),
	computed: {
		companyUnits() {
			return getAllCompanyUnits();
		},
		tabs() {
			return stepsKeys;
		}
	},
	methods: {
		async getProductPrices() {
			const productPrices = await Promise.all(
				this.formData.prices.map(async item => {
					const price = await PricePresenter.list(item.layer.id, this.productId);

					return {
						layerId: item.layer.id,
						...price[0]
					};
				})
			);

			return productPrices;
		},
		async getProductAvailabilties() {
			const availabilitiesResponse = await Promise.all(
				this.companyUnits.map(async item => {
					const availability = await AvailabilityPresenter.list({
						ids: this.productId,
						companyUnitId: item.id
					});

					return availability.items;
				})
			);

			return availabilitiesResponse.flat(Infinity);
		},
		async getProduct() {
			this.product = await ProductPresenter.getProductById(this.productId);
			this.product.availabilities = await this.getProductAvailabilties();
			this.product.prices = await this.getProductPrices();
			this.mountData();
		},
		async getLayers() {
			const layersResponse = await LayerPresenter.list({
				isBase: true
			});

			this.formData.prices = [...layersResponse.items.map(layer => ({
				layer: {
					id: layer.id,
					name: layer.name
				},
				value: null
			}))];
		},
		getAvaliabilities() {
			const items = this.companyUnits.map(companyUnit => ({
				status: availabilityKeys.AVAILABLE,
				companyUnit
			}));

			this.formData.availabilities = [...items];
		},
		async getPrices() {
			return Promise.all([
				await this.getLayers(),
				await this.getAvaliabilities()
			]);
		},
		addIngredient() {
			this.formData.medicine.compositions.push({
				concentration: null,
				activeIngredient: null,
				unitOfMeasurementSymbol: null
			});
		},
		removeIngredient(index) {
			this.formData.medicine.compositions.splice(index, 1);
		},
		async next() {
			if (this.tabSelected === stepsKeys.product)
				return this.goto(stepsKeys.pricing);

			if (this.tabSelected === stepsKeys.pricing)
				return this.save();

			throw new Error(`Step ${this.tabSelected} não existe.`);
		},
		async back() {
			if (this.tabSelected === stepsKeys.pricing)
				await this.goto(stepsKeys.product);
		},
		async goto(step) {
			const stepValidated = await this.$refs[this.tabSelected].validate();
			if (!stepValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário incompleto',
					color: 'error'
				});
				return;
			}

			if (!Object.keys(stepsKeys).includes(step))	{
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Step desconhecido',
					color: 'error'
				});
				return;
			}

			this.tabSelected = step;
		},
		async save() {
			this.submiting = true;
			const productParams = {
				...this.formData.product,
				prices: this.formData.prices.map(item => ({
					layerId: item.layer.id,
					value: Number(this.$functions.onlyNumber(item.value)),
					originalValue: Number(this.$functions.onlyNumber(item.originalValue))
				})),
				availabilities: this.formData.availabilities.map(item => ({
					status: item.status,
					originalStatus: item.originalStatus,
					companyUnitId: item.companyUnit.id
				}))
			};

			if (this.formData.product.type === itemType.medicine)
				productParams.medicine = this.formData.medicine;

			try {
				const response = await ProductPresenter.updateProduct(this.productId, productParams);
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: response.result,
					color: response.level,
					timeout: 10000
				});
				this.$emit('close');
			} finally {
				this.submiting = false;
			}
		},
		mountData() {
			const productResponse = this.product;
			this.formData.product.umbrellaItemId = productResponse.umbrellaItemId;
			this.formData.product.type = productResponse.drug ? 'medicine' : 'product';
			this.formData.product.associatedConditionId = productResponse.associatedConditionId;
			this.formData.product.group = productResponse.group;
			this.formData.product.name = productResponse.name.toUpperCase();
			this.formData.product.brand = {
				id: productResponse.brandId,
				name: productResponse.brand
			};
			this.formData.product.category = productResponse.category;
			this.formData.product.presentation = productResponse.presentation;
			this.formData.product.description = productResponse.description;
			this.formData.product.storageCondition = productResponse.storageCondition;
			this.formData.product.ncm = productResponse.ncm;
			this.formData.product.gtin = productResponse.gtin;
			this.formData.product.gtins = productResponse.gtins;
			this.formData.product.height = productResponse.height;
			this.formData.product.width = productResponse.width;
			this.formData.product.length = productResponse.length;
			this.formData.product.quantity = productResponse.quantity;
			this.formData.product.unitOfMeasurementSymbol = productResponse.unitOfMeasurement.symbol;
			this.formData.product.quantityForSale = productResponse.quantityForSale;
			this.formData.product.unitOfMeasurementForSaleSymbol = productResponse.unitOfMeasurementForSale?.symbol;
			this.formData.product.grossWeight = productResponse.grossWeight;
			this.formData.product.netWeight = productResponse.netWeight;
			this.formData.product.manufacturer = productResponse.manufacturer;

			if (productResponse.drug) {
				this.formData.medicine.pmc = productResponse.drug.pmc;
				this.formData.medicine.stripe = productResponse.drug.stripe;
				this.formData.medicine.type = productResponse.drug.type;
				this.formData.medicine.anvisaId = productResponse.drug.anvisaId;
				this.formData.medicine.pnu = productResponse.drug.pnu;
				this.formData.medicine.pbmId = productResponse.drug.pbm ? productResponse.drug.pbm.id : null;
				this.formData.medicine.referenceDrug = productResponse.drug.referenceDrug;
				this.formData.medicine.prescriptionRule = productResponse.drug.prescriptionRule;
				this.formData.medicine.dosageFormId = productResponse.drug.dosageForm.id;
				this.formData.medicine.pmc = productResponse.drug.pmc || {};
				this.formData.medicine.pf = productResponse.drug.pf || {};
				this.formData.medicine.dosageFormId = productResponse.drug.dosageForm
					? productResponse.drug.dosageForm.id
					: null;
				this.formData.medicine.atcId = productResponse.drug.atc
					? productResponse.drug.atc.id
					: null;
				this.formData.medicine.atcName = productResponse.drug.atc
					? productResponse.drug.atc.atc
					: null;
				this.formData.medicine.compositions = productResponse.drug.compositions.map(item => ({
					concentration: item.concentration,
					activeIngredient: item.activeIngredient,
					unitOfMeasurementSymbol: item.unitOfMeasurement.symbol
				}));
				this.formData.medicine.routeAdministrationIds = productResponse.drug.routesOfAdministration.map(item => item.id);

				this.formData.medicine.properties.aggregateItem = getPropertyValue(productResponse.drug.properties, 'aggregateItem');
				this.formData.medicine.properties.isLiquid = Boolean(getPropertyValue(productResponse.drug.properties, 'isLiquid'));
				this.formData.medicine.properties.isDivisible = Boolean(getPropertyValue(productResponse.drug.properties, 'isDivisible'));
				this.formData.medicine.properties.isOutOfBox = Boolean(getPropertyValue(productResponse.drug.properties, 'isOutOfBox'));
			}

			this.formData.availabilities = this.formData.availabilities.map(item => {
				const availabilityFound = this.product.availabilities
					.find(availabilityItem => Number(availabilityItem.companyUnitId) === Number(item.companyUnit.id));
				const status = availabilityFound ? availabilityFound.status : item.status;
				return {
					...item,
					status,
					originalStatus: status
				};
			});

			this.formData.prices = this.formData.prices.map(item => {
				const priceFound = this.product.prices
					.find(priceItem => Number(priceItem.layerId) === Number(item.layer.id));
				const price = priceFound ? priceFound.price : null;

				return {
					...item,
					value: price,
					originalValue: price
				};
			});
		}
	}
};
</script>

<style lang="scss" scoped>
	.container {
		max-width: 800px !important;
		min-width: 800px !important;
		padding: 24px 0px;
	}

	.toolbarFlex {
		display: flex !important;
		flex-direction: row !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.cardBody {
		background-color: rgba(255, 234, 204, 1);
		height: 100vh;
	}
</style>
