export const boxStatusBoxMap = {
	PROCESS_PICKING: 'PROCESS_PICKING',
	PICKED: 'PICKED',
	BILLED: 'BILLED',
	IN_PRODUCTION: 'IN_PRODUCTION',
	PRODUCED: 'PRODUCED'
};

export const getAllStatusBox = () => ({
	WAITING: 'Aguardando Iniciar',
	PURCHASED: 'Comprada',
	SERVICE_STARTED: 'Serviço iniciado',
	SERVICE_ENDED: 'Serviço finalizado',
	PICKING: 'Picking',
	[boxStatusBoxMap.PROCESS_PICKING]: 'Processando picking',
	[boxStatusBoxMap.PICKED]: 'Faturamento',
	[boxStatusBoxMap.BILLED]: 'Faturada',
	[boxStatusBoxMap.IN_PRODUCTION]: 'Em produção',
	[boxStatusBoxMap.PRODUCED]: 'Produzida',
	CHECKED: 'Conferida',
	DESPATCHED: 'Expedida',
	SENT: 'Enviada',
	DELAYED: 'Atrasada',
	DELIVERED: 'Entregue',

	// Não aparecem em box.status
	NO_CHANGES: 'Sem alterações',
	SUPPORT_STARTED: 'Suporte iniciado',
	PENDENCY_REQUESTED: 'Pendência solicitada',
	PENDENCIES_RESOLVED: 'Pendências concluídas',
	CHANGE_REQUESTED: 'Alteração solicitada',
	CHANGED: 'Alterada',
	BEGIN_UPDATED: 'Data de início alterada',
	PAID: 'Paga',
	CHARGED: 'Cobrada'
});

export const getStatusBox = status => getAllStatusBox()[status] || '';

export const getPrecedence = status => {
	const precedenceMap = {
		WAITING: () => ({
			name: 'WAITING',
			precedence: []
		}),
		PURCHASED: () => ({
			name: 'PURCHASED',
			precedence: ['WAITING']
		}),
		SERVICE_STARTED: () => ({
			name: 'SERVICE_STARTED',
			precedence: ['WAITING']
		}),
		SERVICE_ENDED: () => ({
			name: 'SERVICE_ENDED',
			precedence: getPrecedence('SERVICE_STARTED')
		}),
		PICKING: () => ({
			name: 'PICKING',
			precedence: [...getPrecedence('SERVICE_ENDED'), ...getPrecedence('SERVICE_STARTED'), ...getPrecedence('PURCHASED')]
		}),
		PICKED: () => ({
			name: 'PICKED',
			precedence: getPrecedence('PICKING')
		}),
		[boxStatusBoxMap.BILLED]: () => ({
			name: boxStatusBoxMap.BILLED,
			precedence: getPrecedence('PICKED')
		}),
		[boxStatusBoxMap.IN_PRODUCTION]: () => ({
			name: boxStatusBoxMap.IN_PRODUCTION,
			precedence: getPrecedence(boxStatusBoxMap.BILLED)
		}),
		[boxStatusBoxMap.PRODUCED]: () => ({
			name: boxStatusBoxMap.PRODUCED,
			precedence: getPrecedence(boxStatusBoxMap.IN_PRODUCTION)
		}),
		CHECKED: () => ({
			name: 'CHECKED',
			precedence: getPrecedence(boxStatusBoxMap.PRODUCED)
		}),
		DESPATCHED: () => ({
			name: 'DESPATCHED',
			precedence: getPrecedence('CHECKED')
		}),
		DELAYED: () => ({
			name: 'DELAYED',
			precedence: getPrecedence('DESPATCHED')
		}),
		DELIVERED: () => ({
			name: 'DELIVERED',
			precedence: [...getPrecedence('DESPATCHED'), getPrecedence('DELAYED')]
		})
	};
	if (!status || !precedenceMap[status])
		return null;
	const { name, precedence } = precedenceMap[status]();
	return [name, ...precedence];
};
